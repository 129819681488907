import React, { FC, useEffect, useState, ReactNode, Fragment, useRef } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ApiService } from '../../services';
import { IOrganization } from '../../models/organization';
import { IUser } from '../../models/user';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
interface Props {
  user: IUser;
}

// eslint-disable-next-line react/prop-types
export const OrganizationSelect: FC<Props> = ({ user }: { user: IUser }) => {
  const [selected, setSelected] = useState<string>(user.organization ?? '0');
  const [open, setOpen] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const orgRef = useRef<string>('0');

  useEffect(() => {
    ApiService.getOrganizations()
      .then((value) => {
        setOrganizations(value);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const assignOrganization = async () => {
    setSelected(orgRef.current);
    setOpenConfirmDialog(false);
    await ApiService.assignOrganization(user._id, orgRef.current);
  };

  const renderDialog = (): ReactNode => {
    return (
      <Dialog
        open={openConfirmDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Are you sure you want to enable MechVision for this user?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This user will be assigned to selected organization by you.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={assignOrganization} color="primary">
            YES
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setOpenConfirmDialog(false);
            }}
          >
            NO
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Fragment>
      <Select
        className="w-44 mt-1 text-blue-800 text-base"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        placeholder="Select Organization"
        value={selected}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(e: any) => {
          setOpenConfirmDialog(true);
          orgRef.current = e.target.value;
        }}
        inputProps={{
          id: 'organization-select',
        }}
        displayEmpty
      >
        <MenuItem className="italic text-gray-500 text-sm" value={'0'} disabled>
          Select Organization
        </MenuItem>
        {organizations.map((item, index) => {
          return (
            <MenuItem className="text-black text-sm" key={index} value={item._id}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
      {renderDialog()}
    </Fragment>
  );
};
