import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import { Avatar, Button, InputAdornment, Menu, MenuItem, TextField } from '@material-ui/core';
import { SearchOutlined, PersonOutlineOutlined, ArrowDropDown } from '@material-ui/icons';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';

import IMG_LOGO from '../../assets/images/logo.png';
import { AuthService } from '../../services';
import ROUTES from '../../constants/routes';
import { IApplicationState } from '../../redux/reducers';
import { setSearchKeyword } from '../../redux/actions/actions';
import { QueryParams } from '../../constants/query-params';

export interface IHeaderState {
  menuAnchorEl: Element | null | undefined;
  search: string;
}

class Header extends Component<Props, IHeaderState> {
  static defaultProps = { showSearch: true };

  constructor(props: Props) {
    super(props);

    const params = new URLSearchParams(props?.location.search);

    this.state = {
      menuAnchorEl: null,
      search: params.get(QueryParams.SEARCH) ?? '',
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!_.isEqual(prevProps?.location, this.props?.location)) {
      const params = new URLSearchParams(this.props?.location.search);
      this.setState({
        search: params.get(QueryParams.SEARCH) ?? '',
      });
    }
  }

  render() {
    const { user } = this.props.userReducer;

    return (
      <div className="dv-header flex p-3 items-center border-b">
        {/* logo */}
        <img src={IMG_LOGO} className="w-28 mx-8" alt="logo" />

        <div className="flex flex-1 mx-12">
          {this.props.showSearch ? (
            <form className="flex flex-1" onSubmit={(e) => this.handleSubmit(e)}>
              {/* search bar */}
              <TextField
                className="txt-search flex-1"
                variant="outlined"
                type="search"
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined className="txt-primary-light" />
                    </InputAdornment>
                  ),
                }}
                value={this.state.search}
                onChange={(e) => {
                  if (this.state.search.length > 1 && !e.target.value) {
                    // clear
                    this.setState({ search: e.target.value }, () => {
                      this.handleSubmit();
                    });
                  } else {
                    this.setState({ search: e.target.value });
                  }
                }}
              />
            </form>
          ) : null}
        </div>

        {/* menu */}
        {user ? (
          <>
            <Button
              className="but-menu outline-none normal-case mx-8 p-0 txt-primary"
              onClick={(e) => this.onMenu(e)}
            >
              <div className="flex items-center">
                {/* photo */}
                <Avatar className="mr-2 w-9 h-9">
                  <PersonOutlineOutlined />
                </Avatar>

                {/* user name */}
                <span>Admin</span>

                {/* dropdown mark */}
                <ArrowDropDown />
              </div>
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={this.state.menuAnchorEl}
              keepMounted
              open={Boolean(this.state.menuAnchorEl)}
              onClose={() => this.onClose()}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => this.onButSignOut()}>Logout</MenuItem>
            </Menu>
          </>
        ) : null}
      </div>
    );
  }

  onMenu(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({ menuAnchorEl: event.currentTarget });
  }

  onClose() {
    this.setState({ menuAnchorEl: null });
  }

  handleSubmit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    //
    // parse query params
    //
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);

    if (this.state.search) {
      queryParams.set(QueryParams.SEARCH, this.state.search);
    } else {
      queryParams.delete(QueryParams.SEARCH);
    }

    // refresh with search query param
    // this.props.history.push(`${this.props.location.pathname}?${this.queryParams.toString()}`);
    this.props.history.replace({
      pathname: location.pathname,
      search: `?${queryParams.toString()}`,
    });
  }

  /**
   * logout
   */
  onButSignOut() {
    AuthService.signOut();

    // go to login page
    this.props.history?.replace(ROUTES.LOGIN);
  }
}

export interface Props extends IApplicationState, PropsFromDispatch, RouteComponentProps {
  showSearch: boolean;
}

interface PropsFromDispatch {
  setSearchKeyword: typeof setSearchKeyword;
}

const mapStateToProps = (state: IApplicationState) => state;
const mapDispatchToProps = {
  setSearchKeyword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
