import { ApiService } from '../services';
import { IReport } from './report';

export const UserData = {
  TYPE_ADMIN: 'admin',
  TYPE_ADMIN_USER: 'admin-user',
  TYPE_USER: 'user',
};

export interface IUser {
  _id: string;
  email: string;
  name: string;
  photo: string;
  city: string;
  zipCode: string;
  country: string;
  type: string;
  createdAt: Date;
  lastActiveAt?: Date;

  needApproval: boolean;
  uploadEnabled: boolean;
  bannedAt?: Date;
  deletedAt?: Date;

  postsCount?: number;
  organization?: string;

  reports: IReport[];
}

export class User {
  static PHOTO_PATH = 'profile-images';

  data: IUser;

  constructor(user: IUser) {
    this.data = user;
  }

  photoUrl(): string {
    if (this.data.photo) {
      return `${ApiService.urlFileBase}/${User.PHOTO_PATH}/${this.data.photo}`;
    }

    return '';
  }
}
