import React, { Component, FormEvent, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { setShowLoading } from '../../redux/actions/actions';
import { IApplicationState } from '../../redux/reducers';
import { Alert } from '@material-ui/lab';
import { IUser, User, UserData } from '../../models/user';
import {
  PersonOutlineOutlined,
  CheckBoxOutlineBlank,
  CheckBox as CheckBoxIcon,
} from '@material-ui/icons';
import {
  Avatar,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core';
import { ApiService } from '../../services';
import { connect } from 'react-redux';

interface IUserDetailState {
  user?: IUser;
  userType: string;
  needApproval: boolean;

  errorMsg: string;
  successMsg: string;
}

class UserDetail extends Component<Props, IUserDetailState> {
  constructor(props: Props) {
    super(props);

    const { userSelected } = props.uiReducer;

    this.state = {
      // ui
      errorMsg: '',
      successMsg: '',

      user: userSelected,
      userType: userSelected?.type,
      needApproval: userSelected?.needApproval ?? false,
    };
  }

  async componentDidMount(): Promise<void> {
    // load user data if userSelected is null
    if (!this.state.user) {
      const userId = this.props.match.params?.id;

      this.props.setShowLoading(true);

      try {
        const user = await ApiService.getUserById(userId);

        this.setState({
          user,
          userType: user.type,
          needApproval: user.needApproval,
        });
      } catch (e) {
        console.log(e);

        // show error
        this.setState({
          errorMsg: e.message,
          successMsg: '',
        });
      }

      this.props.setShowLoading(false);
    }
  }

  render(): ReactElement {
    const { user } = this.state;

    return (
      <div className="dv-container px-4 py-3 text-sm page-user-detail">
        {/* header */}
        <div className="flex">
          {/* title */}
          <div className="pt-2 pb-4 txt-primary text-2xl font-bold">User Detail</div>
        </div>

        <div className="py-4 px-6 bg-light-transparent rounded-lg dv-table-main">
          {/* success notice */}
          {this.state.successMsg ? (
            <Alert
              severity="success"
              className="mb-4"
              onClose={() => this.setState({ successMsg: '' })}
            >
              {this.state.successMsg}
            </Alert>
          ) : null}

          {/* error notice */}
          {this.state.errorMsg ? (
            <Alert
              severity="error"
              className="mb-4"
              onClose={() => this.setState({ errorMsg: '' })}
            >
              {this.state.errorMsg}
            </Alert>
          ) : null}

          <h6 className="font-semibold text-base">User Information</h6>

          <form className="flex my-4" onSubmit={(e) => this.handleSubmit(e)}>
            {/* photo */}
            <Avatar
              className="mr-6 w-24 h-24 bg-gray-300"
              src={user ? new User(user).photoUrl() : ''}
            >
              <PersonOutlineOutlined className="text-7xl" />
            </Avatar>

            {/* info */}
            <div className="flex flex-col flex-1">
              <div className="grid grid-cols-2 gap-4">
                <TextField
                  label="Name"
                  variant="outlined"
                  value={this.state.user?.name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="Email"
                  variant="outlined"
                  value={this.state.user?.email}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>

              <div className="grid grid-cols-3 gap-x-4 gap-y-6 mt-6">
                <TextField
                  label="City"
                  variant="outlined"
                  value={this.state.user?.city}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="Zip Code"
                  variant="outlined"
                  value={this.state.user?.zipCode}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="Country"
                  variant="outlined"
                  value={this.state.user?.country}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                {/* role */}
                <FormControl variant="outlined">
                  <InputLabel htmlFor="select-role">Role</InputLabel>
                  <Select
                    label="Role"
                    native
                    value={this.state.userType}
                    onChange={(e) => {
                      this.setState({
                        userType: e.target.value as string,
                      });
                    }}
                    inputProps={{
                      name: 'role',
                      id: 'select-role',
                    }}
                  >
                    <option value={UserData.TYPE_USER}>User</option>
                    <option value={UserData.TYPE_ADMIN_USER}>Admin</option>
                  </Select>
                </FormControl>

                {/* need approval */}
                <div className="flex justify-center">
                  <FormControlLabel
                    className="text-gray-700 self-center"
                    control={
                      <Checkbox
                        checked={this.state.needApproval}
                        onChange={(e) => {
                          this.setState({ needApproval: e.target.checked });
                        }}
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" color="primary" />}
                        name="needApproval"
                      />
                    }
                    label="Need Approval For Uploads"
                  />
                </div>
              </div>

              {/* actions */}
              <div className="flex mt-6">
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  async handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const { user, userType, needApproval } = this.state;

    if (!user) {
      return;
    }

    this.props.setShowLoading(true);

    try {
      await ApiService.updateUser(user._id, {
        type: userType,
        needApproval,
      });

      // success message
      this.setState({ successMsg: 'Updated user successfully' });
    } catch (e) {
      console.log(e);

      // show error
      this.setState({ errorMsg: e.message });
    }

    this.props.setShowLoading(false);
  }
}

interface IMatchParams {
  id: string;
}

interface PropsFromDispatch {
  setShowLoading: typeof setShowLoading;
}

export interface Props
  extends IApplicationState,
    PropsFromDispatch,
    RouteComponentProps<IMatchParams> {}

const mapStateToProps = (state: IApplicationState) => state;
const mapDispatchToProps = {
  setShowLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
