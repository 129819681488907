import moment from 'moment';
import { Component, ReactElement, ReactNode } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { FileCopy, DoneAll } from '@material-ui/icons';
import { IconButton, Button, Input } from '@material-ui/core';
import Lightbox from 'react-image-lightbox';

import { IPost, Post } from '../../models/post';
import { IApplicationState } from '../../redux/reducers';
import { setItemSelected, setShowLoading } from '../../redux/actions/actions';
import { ApiService } from '../../services';
import { queryString, string2Array } from '../../helpers/utils';
import { IUser } from '../../models/user';
import ROUTES from '../../constants/routes';
import { QueryParams } from '../../constants/query-params';
import TagInput from '../../components/TagInput/TagInput';
import IndustryInput from '../../components/IndustryInput/IndustryInput';
import './styles.scss';
import { IComment } from '../../models/comment';
import { Comments } from '../../components/Comments/Comments';
import { UserPost } from '../../components/UserPost';

interface IPostDetailState {
  post?: IPost;
  errorMsg: string;

  showFullImage: boolean;
  tags: string[];
  comments: IComment[];
  industries: string[];
  copied: boolean;
}

class PostDetail extends Component<Props, IPostDetailState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      // ui
      errorMsg: '',
      showFullImage: false,

      post: props.uiReducer.itemSelected,
      tags: [],
      industries: [],
      comments: [],
      copied: false,
    };
  }

  async componentDidMount(): Promise<void> {
    this.props.setShowLoading(true);

    try {
      // load user data if userSelected is null
      const postId = this.state.post ? this.state.post._id : this.props.match.params?.id;
      if (!this.state.post) {
        const post = await ApiService.getPostById(postId);
        this.setState({ post });
      }

      const { comments } = await ApiService.getComments(postId);
      this.setState({ comments });

      // load tags
      const tags = await ApiService.getTags();
      const data = await ApiService.getIndustries();
      this.setState({ tags, industries: data.map((i) => i.name) });
    } catch (e) {
      console.log(e);

      // show error
      if (e instanceof Error) {
        this.setState({
          errorMsg: e.message,
        });
      }
    }

    this.props.setShowLoading(false);
  }

  componentWillUnmount(): void {
    // clear item selected from reducer
    this.props.setItemSelected(null);
  }

  render(): ReactElement {
    const { post } = this.state;

    return (
      <div className="dv-container px-4 py-3 text-sm page-post-detail">
        {/* header */}
        <div className="flex">
          {/* title */}
          <div className="flex-1 pt-2 pb-4 txt-primary text-2xl font-bold">Image Detail</div>
          <IconButton
            aria-label="delete"
            className="outline-none"
            onClick={() => this.copyToClipboard()}
          >
            {!this.state.copied ? (
              <FileCopy fontSize="small" className="txt-primary pr-1" />
            ) : (
              <DoneAll fontSize="small" className="txt-primary pr-1" />
            )}
            <div className="txt-primary text-base font-bold txt-primary">
              {this.state.copied ? 'Copied To Clipboard' : 'Copy Share Link'}
            </div>
          </IconButton>
        </div>

        <div className="py-4 px-6 bg-light-transparent rounded-lg dv-table-main">
          {/* error notice */}
          {this.state.errorMsg ? (
            <Alert
              severity="error"
              className="mb-4"
              onClose={() => this.setState({ errorMsg: '' })}
            >
              {this.state.errorMsg}
            </Alert>
          ) : null}

          <h6 className="font-semibold text-base">Image Information</h6>

          <div className="flex my-4">
            {/* image */}
            <UserPost post={post} onClick={() => this.setState({ showFullImage: true })} />

            {/* info */}
            <div className="flex flex-col flex-1 text-sm text-gray-800">
              {/* title */}
              <div className="flex mb-5">
                <div className="w-24 pt-3 text-gray-600">Title:</div>
                <Input
                  className="flex-1 text-sm"
                  placeholder="Input Title"
                  required
                  value={post?.title || ''}
                  onChange={(e) => {
                    this.setState({
                      post: { ...(this.state.post as IPost), title: e.target.value },
                    });
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  className="rounded-full ml-4 outline-none ph-2 min-w-0"
                  onClick={() => this.updatePost()}
                >
                  Update
                </Button>
              </div>

              {/* author */}
              <div className="flex mb-3">
                <div className="w-24 text-gray-600">Author:</div>
                <div className="font-semibold">{(post?.user as IUser)?.name ?? 'Admin'}</div>
              </div>

              {/* address */}
              <div className="flex mb-3">
                <div className="w-24 text-gray-600">Address:</div>
                <div className="font-semibold">{post?.address ?? 'N/A'}</div>
              </div>

              {/* date */}
              <div className="flex mb-3">
                <div className="w-24 text-gray-600">Date:</div>
                <div className="font-semibold">
                  {moment(post?.createdAt).format('MM/DD/yyyy HH:mm:ss')}
                </div>
              </div>

              {/* downloads */}
              <div className="flex mb-4">
                <div className="w-24 text-gray-600">Downloads:</div>
                <Link
                  to={{
                    pathname: `${ROUTES.USERS_DOWNLOADED_POST}/${post?._id}`,
                  }}
                >
                  <div className="font-semibold text-blue-600">{post?.downloadCount}</div>
                </Link>
              </div>

              {/* tags */}
              <div className="flex">
                <div className="w-24 text-gray-600 mt-1">Tags:</div>

                <TagInput
                  tags={post ? post.tags : []}
                  tagsAll={this.state.tags}
                  onChangeTags={(tags) => this.updateTags(tags)}
                  onClickTag={(t) => this.onClickTag(t)}
                />
              </div>

              <div className="flex">
                <div className="w-24 text-gray-600 mt-1">Industries:</div>

                <IndustryInput
                  selected={string2Array(post?.industry)}
                  candidates={this.state.industries}
                  onChangeIndustries={(industries) => this.updateIndustries(industries)}
                  onClickIndustryLabel={(i) => this.onClickIndustry(i)}
                />
              </div>

              <div className="flex">
                <div className="w-24 text-gray-600 mt-1">Comments:</div>

                <Comments comments={this.state.comments} />
              </div>
            </div>
          </div>
        </div>

        {this.renderImageModal()}
      </div>
    );
  }

  async copyToClipboard() {
    if (!this.state.post) {
      return;
    }
    const longUrl = new Post(this.state.post).imageUrl();
    const shorten = await ApiService.shortenUrl(longUrl);
    navigator.clipboard.writeText(shorten);
    this.setState({ copied: true });
  }

  renderImageModal(): ReactNode {
    const { post, showFullImage } = this.state;

    if (post && showFullImage) {
      return (
        <Lightbox
          mainSrc={new Post(post).imageUrl()}
          onCloseRequest={() => this.setState({ showFullImage: false })}
        />
      );
    }

    return null;
  }

  updateTags(tags: string[]) {
    const { post } = this.state;
    if (post) {
      post.tags = tags;
    }

    this.setState({ post }, () => {
      this.updatePost();
    });
  }

  updateIndustries(industries: string[]) {
    const { post } = this.state;
    if (post) {
      post.industry = industries;
    }

    this.setState({ post }, () => {
      this.updatePost();
    });
  }

  onClickIndustry(industry: string) {
    const params = new URLSearchParams(this.props?.location.search);

    // search posts with the tag
    this.props.history.push({
      pathname: `${ROUTES.IMAGES}`,
      search: `?${queryString({
        industry,
        from: params.get(QueryParams.FROM) ?? '',
      })}`,
    });
  }

  onClickTag(tag: string) {
    const params = new URLSearchParams(this.props?.location.search);

    // search posts with the tag
    this.props.history.push({
      pathname: `${ROUTES.IMAGES}`,
      search: `?${queryString({
        tag,
        from: params.get(QueryParams.FROM) ?? '',
      })}`,
    });
  }

  async updatePost() {
    const { post } = this.state;
    if (!post) {
      return;
    }

    this.props.setShowLoading(true);

    try {
      await ApiService.updatePost(post);
    } catch (e) {
      console.log(e);

      // show error
      if (e instanceof Error) {
        this.setState({ errorMsg: e.message });
      }
    }

    this.props.setShowLoading(false);
  }
}

interface IMatchParams {
  id: string;
}

interface PropsFromDispatch {
  setShowLoading: typeof setShowLoading;
  setItemSelected: typeof setItemSelected;
}

interface Props extends IApplicationState, PropsFromDispatch, RouteComponentProps<IMatchParams> {}

const mapStateToProps = (state: IApplicationState) => state;
const mapDispatchToProps = {
  setShowLoading,
  setItemSelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostDetail);
