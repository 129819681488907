import { FC } from 'react';
import moment from 'moment';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { IComment } from '../../models/comment';
import { User } from '../../models/user';
import { queryString, removeLeadingSlash } from '../../helpers/utils';
import ROUTES from '../../constants/routes';

interface Props {
  comments: IComment[];
}

const useStyles = makeStyles({
  '@global': {
    '.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: '#fff',
    },
    '.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: '#fff',
    },
  },
});

export const Comments: FC<Props> = ({ comments }: Props) => {
  useStyles();

  const renderTree = (comment: IComment) => (
    <TreeItem
      key={comment._id}
      nodeId={comment._id}
      label={
        <div className="flex bg-gray-300 p-2 m-2 br-5 rounded-lg">
          <Link
            to={{
              pathname: `${ROUTES.USERS}/${comment.user._id}`,
              search: `?${queryString({ from: removeLeadingSlash(ROUTES.USERS) })}`,
            }}
          >
            <Avatar src={new User(comment.user).photoUrl()} />
          </Link>

          <div className="flex-1">
            <h6 className="text-sm pl-2">{comment.user.name}</h6>
            <h6 className="text-xs pl-2">{`${comment.user.city}, ${comment.user.country}`}</h6>
            <p className="p-1 pl-2">{comment.content}</p>
            <h6 className="text-right text-gray-400">
              {moment(comment.createdAt).fromNow() ?? ''}
            </h6>
          </div>
        </div>
      }
    >
      {comment.childs.map((node) => renderTree(node))}
    </TreeItem>
  );

  return (
    <TreeView
      className="w-full"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={['root']}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {comments.map((comment) => renderTree(comment))}
    </TreeView>
  );
};
