export const countryToFlag = (isoCode: string): string => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
};

export const removeTrailingSlash = (path: string): string => {
  return path.replace(/\/$/, '');
};

export const removeLeadingSlash = (path: string): string => {
  return path.replace(/^\/+/, '');
};

export const makeEmptyArray = (length: number): any[] => {
  const arr = [];
  for (let i = 0; i < length; i++) {
    arr.push({});
  }

  return arr;
};

export const queryString = (params: { [prop: string]: string }): string => {
  return new URLSearchParams(params).toString();
};

export const pushUnique = (array: string[], value: string): void => {
  if (array.indexOf(value) < 0) {
    array.push(value);
  }
};

export const extractFileName = (name: string): string => {
  const arrVars = name.split('.');
  if (arrVars.length < 2) {
    return name;
  }

  arrVars.pop();
  return arrVars.join('.');
};

export const extractFileExt = (name: string): string | undefined => {
  const arrVars = name.split('.');
  if (arrVars.length < 2) {
    return '';
  }

  return arrVars.pop();
};

export const string2Array = (val: string | string[] | undefined): string[] => {
  if (typeof val === 'string') {
    return [val];
  } else if (Array.isArray(val)) {
    return val;
  } else {
    return ['GENERAL'];
  }
};

export const makeTag = (val: string | undefined): string => {
  if (!val || !val.trim()) return '';

  if (val[0] === '#') return val;
  return `#${val}`;
};
