import { combineReducers } from 'redux';

import { reducer as UserReducer, UserPropsState } from './UserReducer';
import { reducer as UiReducer, UiPropsState } from './UiReducer';

// The top-level state object
export interface IApplicationState {
  userReducer: UserPropsState;
  uiReducer: UiPropsState;
}

export default combineReducers({
  userReducer: UserReducer,
  uiReducer: UiReducer,
});
