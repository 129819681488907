import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Avatar, Button, Collapse, IconButton, List, ListItem, Tooltip } from '@material-ui/core';
import { Delete, PersonOutlineOutlined, Flag, Done, DoneAll } from '@material-ui/icons';
import moment from 'moment';

import BasePosts, { IPostsState } from './BasePosts';
import { Props } from '../BaseList';
import SortMenu from '../../components/SortMenu/SortMenu';
import { IApplicationState } from '../../redux/reducers';
import { setShowLoading } from '../../redux/actions/actions';
import { ApiService } from '../../services';
import { UiHelper } from '../../helpers/ui-helper';
import { QueryParams } from '../../constants/query-params';
import { IPost, Post } from '../../models/post';
import { IUser, User } from '../../models/user';
import { IReport } from '../../models/report';
import { makeTag, string2Array } from '../../helpers/utils';
import { UserPost } from '../../components/UserPost';

interface IPostsReportedState extends IPostsState {
  postIdsExpanded: string[];
}

class PostsReported extends BasePosts<Props, IPostsReportedState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ...this.state,

      postIdsExpanded: [],
    };
  }

  render() {
    return (
      <div className="dv-container px-4 py-3 text-sm page-posts">
        {/* header */}
        <div className="flex justify-between items-center">
          {/* title */}
          <div className="pt-2 pb-4 txt-primary text-2xl font-bold">Reported Images</div>

          {/* sort */}
          <SortMenu
            sort={this.getSortBy()}
            sortItems={[
              { value: 'similarity', label: 'Similarity' },
              { value: 'createdAt', label: 'Date Uploaded' },
              { value: 'downloadCount', label: 'Downloads' },
            ]}
            onSort={this.onSortBy.bind(this)}
          />
        </div>

        {/* list */}
        {this.renderList('No Pending Images')}

        {this.renderDeleteConfirmDialog()}
        {this.renderImageModal()}
      </div>
    );
  }

  renderListCore(): ReactNode {
    return <List>{this.state.items.map((item: IPost) => this.renderItem(item))}</List>;
  }

  renderItem(item: IPost) {
    const post = new Post(item);

    return (
      <div className="flex flex-col border-b" key={item._id}>
        <ListItem className="flex py-3 px-2 items-stretch">
          {/* image */}
          <UserPost
            post={item}
            onClick={(e) => {
              e.stopPropagation();

              this.setState({ postIdModal: item._id });
            }}
            outlined="text-6xl"
            className="w-28 h-28 bg-gray-300"
          />

          {/* description */}
          <div className="flex flex-col flex-1 mx-3 justify-between self-stretch">
            <div className="flex flex-col">
              <div className="text-base">{item.title}</div>

              {/* tags */}
              <div className="text-sm txt-primary-light">
                {item.tags.map((t) => makeTag(t)).join(' ')}
              </div>

              {/* industries */}
              <div className="text-sm text-gray-600">{string2Array(item.industry).join(', ')}</div>
              <div className="text-sm txt-primary-light">{item.address ?? ''}</div>
            </div>

            {/* uploaded user */}
            <div className="flex items-center">
              <Avatar className="mr-2 w-8 h-8" src={post.user?.photoUrl()}>
                <PersonOutlineOutlined />
              </Avatar>
              {/* user name */}
              <span className="txt-primary">{post.user?.data.name}</span>
            </div>
          </div>

          {/* actions */}
          <div className="flex flex-col justify-between text-gray-500">
            <div className="flex items-center">
              {/* show report */}
              <Button
                className={`mr-4 outline-none text-gray-500 ${
                  this.isExpanded(item) ? 'bg-gray-300' : ''
                }`}
                startIcon={<Flag />}
                onClick={() => this.onExpandItem(item)}
              >
                {item.reports.length}
              </Button>

              {/* resolved */}
              <Button
                color="primary"
                className="outline-none normal-case mr-2"
                endIcon={<DoneAll />}
                onClick={() => this.onRemoveReportAll(item)}
              >
                Mark as Resolved
              </Button>

              {/* delete */}
              <Tooltip title="Delete Image" placement="top">
                <IconButton
                  aria-label="delete"
                  className="outline-none"
                  onClick={() => this.setState({ postIdDelete: item._id })}
                >
                  <Delete fontSize="small" className="text-gray-400" />
                </IconButton>
              </Tooltip>
            </div>

            <div className="text-right text-gray-600 pr-4">{item.downloadCount} Downloads</div>
          </div>
        </ListItem>

        <Collapse in={this.isExpanded(item)} timeout="auto" unmountOnExit>
          <div className="pl-28">
            {item.reports.map((r: IReport) => {
              return this.renderReportItem(item, r);
            })}
          </div>
        </Collapse>
      </div>
    );
  }

  renderReportItem(post: IPost, report: IReport) {
    const userReported = new User(report.user as IUser);

    return (
      <div className="border-t flex px-4 py-2 relative text-gray-600 text-xs" key={report._id}>
        <div className="flex flex-col flex-1">
          {/* content */}
          <div className="text-sm">{report.content}</div>
          {/* type */}
          <div className="text-gray-500">{report.type}</div>
          {/* user */}
          <div className="flex items-center mt-2">
            <Avatar className="mr-2 w-6 h-6 bg-gray-300" src={userReported.photoUrl()}>
              <PersonOutlineOutlined className="text-base" />
            </Avatar>
            {/* user name */}
            <span className="txt-primary">{userReported.data.name}</span>
          </div>
        </div>

        <div className="flex flex-col items-end justify-between">
          {/* resolved */}
          <Button
            className="outline-none normal-case text-green-600"
            endIcon={<Done />}
            size="small"
            onClick={() => this.onRemoveReport(post, report)}
          >
            Mark as Resolved
          </Button>

          {/* date */}
          <div className="text-gray-400">
            {moment(report.createdAt).format('MM/DD/yyyy HH:mm:ss')}
          </div>
        </div>
      </div>
    );
  }

  async loadData() {
    this.props.setShowLoading(true);

    const params = new URLSearchParams(this.props?.location.search);

    try {
      const res = await ApiService.getPosts(
        (this.state.page - 1) * this.pageSize,
        this.pageSize,
        UiHelper.sortOptionString(this.getSortBy()),
        undefined,
        undefined,
        params.get(QueryParams.SEARCH),
        undefined,
        params.get(QueryParams.INDUSTRY) ?? undefined,
        true,
      );

      this.setState({
        totalCount: res.count,
        items: res.posts as IPost[],
      });
    } catch (e) {
      console.log(e);

      // show error
      if (e instanceof Error) {
        this.setState({ errorMsg: e.message });
      }
    }

    this.props.setShowLoading(false);
  }

  onExpandItem(item: IPost) {
    const { postIdsExpanded } = this.state;
    const index = postIdsExpanded.findIndex((p) => p === item._id);
    if (index >= 0) {
      postIdsExpanded.splice(index, 1);
    } else {
      postIdsExpanded.push(item._id);
    }

    this.setState({ postIdsExpanded });
  }

  isExpanded(item: IPost) {
    return this.state.postIdsExpanded.findIndex((p) => p === item._id) >= 0;
  }

  async onRemoveReport(item: IPost, report: IReport) {
    this.props.setShowLoading(true);

    try {
      const { items } = this.state;

      await ApiService.deletePostReport(item._id, report._id);

      // update list
      const i = item.reports.findIndex((r) => r._id === report._id);
      item.reports.splice(i, 1);

      this.setState({ items });
    } catch (e) {
      console.log(e);

      // show error
      if (e instanceof Error) {
        this.setState({ errorMsg: e.message });
      }
    }

    this.props.setShowLoading(false);
  }

  async onRemoveReportAll(item: IPost) {
    this.props.setShowLoading(true);

    try {
      await ApiService.deletePostReport(item._id);

      // reload data
      this.loadData();
    } catch (e) {
      console.log(e);

      // show error
      if (e instanceof Error) {
        this.setState({ errorMsg: e.message });
      }
    }

    this.props.setShowLoading(false);
  }
}

const mapStateToProps = (state: IApplicationState) => state;

const mapDispatchToProps = {
  setShowLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostsReported);
