import BaseUsers, { IUsersState, Props } from './BaseUsers';
import React from 'react';
import './styles.scss';
import SortMenu from '../../components/SortMenu/SortMenu';
import { IUser, User } from '../../models/user';
import {
  Avatar,
  Button,
  Collapse,
  IconButton,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import { queryString, removeLeadingSlash } from '../../helpers/utils';
import { Delete, Done, DoneAll, Flag, PersonOutlineOutlined } from '@material-ui/icons';
import moment from 'moment';
import EnableUploadButton from '../../components/EnableUploadButton/EnableUploadButton';
import { ApiService } from '../../services';
import { UiHelper } from '../../helpers/ui-helper';
import { QueryParams } from '../../constants/query-params';
import { IApplicationState } from '../../redux/reducers';
import { setShowLoading, setUserSelected } from '../../redux/actions/actions';
import { connect } from 'react-redux';
import { IReport } from '../../models/report';

interface IUsersReportedState extends IUsersState {
  userIdsExpanded: string[];
}

class UsersReported extends BaseUsers<Props, IUsersReportedState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ...this.state,

      userIdsExpanded: [],
    };
  }

  render() {
    return (
      <div className="dv-container px-4 py-3 text-sm page-users">
        {/* header */}
        <div className="flex justify-between items-center">
          {/* title */}
          <div className="pt-2 pb-4 txt-primary text-2xl font-bold">Reported Users</div>

          {/* sort */}
          <SortMenu
            sort={this.getSortBy()}
            sortItems={[
              { value: 'createdAt', label: 'Date Created' },
              { value: 'lastActiveAt', label: 'Date Active' },
              { value: 'postsCount', label: 'Upload Count' },
            ]}
            onSort={this.onSortBy.bind(this)}
          />
        </div>

        {/* list */}
        {this.renderList('No reported users')}

        {this.renderDeleteConfirmDialog()}
        {this.renderDeletePostsConfirmDialog()}
      </div>
    );
  }

  renderItem(item: IUser, i: number) {
    return (
      <React.Fragment key={i.toString()}>
        <TableRow className="row-user">
          {/* user info */}
          <TableCell className="border-0">{this.renderUserItem(item)}</TableCell>

          {/* ban */}
          <TableCell className="border-0">
            <div className="flex items-center text-gray-500">
              <span>Ban User</span>
              <Switch
                color="secondary"
                size="small"
                className="ml-2"
                checked={!!item.bannedAt}
                onChange={(e) => {
                  this.onBanUser(i, e.target.checked);
                }}
              />
            </div>
          </TableCell>

          {/* delete */}
          <TableCell className="border-0">
            <div className="flex items-center text-gray-500">
              <Link
                to={{
                  pathname: ROUTES.IMAGES,
                  search: `?${queryString({
                    user: item._id,
                    from: removeLeadingSlash(ROUTES.REPORTS_USER),
                  })}`,
                }}
              >
                <Button
                  color="primary"
                  className="self-start outline-none normal-case text-blue-450 w-24"
                  size="small"
                >
                  {item.postsCount} Uploads
                </Button>
              </Link>

              {/* delete uploads */}
              <Tooltip title="Delete Uploads" placement="top">
                <IconButton
                  aria-label="delete"
                  className="outline-none"
                  onClick={() => this.setState({ userIdDeletePosts: item._id })}
                >
                  <Delete fontSize="small" className="text-gray-400" />
                </IconButton>
              </Tooltip>
            </div>
          </TableCell>

          {/* last active */}
          <TableCell className="border-0 txt-primary-light text-sm">
            {item.lastActiveAt ? moment(item.lastActiveAt).format('MM/DD/yyyy HH:mm:ss') : ''}
          </TableCell>

          {/* actions */}
          <TableCell className="border-0 w-36 flex">
            {/* show report */}
            <Button
              className={`mr-2 outline-none text-gray-500 ${
                this.isExpanded(item) ? 'bg-gray-300' : ''
              }`}
              startIcon={<Flag />}
              onClick={() => this.onExpandItem(item)}
            >
              {item.reports ? item.reports.length : 0}
            </Button>

            {/* mark resolved */}
            <Tooltip title="Mark as Resolved" placement="top">
              <IconButton
                aria-label="delete"
                className="outline-none"
                onClick={() => this.onRemoveReportAll(item)}
              >
                <DoneAll fontSize="small" className="text-green-600" />
              </IconButton>
            </Tooltip>

            {/* enable/disable upload */}
            <EnableUploadButton
              enabled={item.uploadEnabled}
              onClick={() => this.enableUserUpload(i, !item.uploadEnabled)}
            />

            <IconButton
              aria-label="delete"
              className="outline-none"
              onClick={() => this.setState({ userIdDelete: item._id })}
            >
              <Delete fontSize="small" className="text-red-400" />
            </IconButton>
          </TableCell>
        </TableRow>

        <TableRow className="border-b">
          <TableCell colSpan={5} className="p-0">
            <Collapse in={this.isExpanded(item)} timeout="auto" unmountOnExit>
              {item.reports.map((r: IReport) => {
                return this.renderReportItem(item, r);
              })}
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  renderReportItem(user: IUser, report: IReport) {
    const userReported = new User(report.user as IUser);

    return (
      <div
        className="border-t flex px-4 py-2 relative text-gray-600 text-xs pl-28"
        key={report._id}
      >
        <div className="flex flex-col flex-1">
          {/* content */}
          <div className="text-sm">{report.content}</div>
          {/* type */}
          <div className="text-gray-500">{report.type}</div>
          {/* user */}
          <div className="flex items-center mt-2">
            <Avatar className="mr-2 w-6 h-6 bg-gray-300" src={userReported.photoUrl()}>
              <PersonOutlineOutlined className="text-base" />
            </Avatar>
            {/* user name */}
            <span className="txt-primary">{userReported.data.name}</span>
          </div>
        </div>

        <div className="flex flex-col items-end justify-between">
          {/* resolved */}
          <Button
            className="outline-none normal-case text-green-600"
            endIcon={<Done />}
            size="small"
            onClick={() => this.onRemoveReport(user, report)}
          >
            Mark as Resolved
          </Button>

          {/* date */}
          <div className="text-gray-400">
            {moment(report.createdAt).format('MM/DD/yyyy HH:mm:ss')}
          </div>
        </div>
      </div>
    );
  }

  async loadData() {
    this.props.setShowLoading(true);

    const params = new URLSearchParams(this.props?.location.search);

    try {
      const res = await ApiService.getUsers(
        (this.state.page - 1) * this.pageSize,
        this.pageSize,
        UiHelper.sortOptionString(this.getSortBy()),
        params.get(QueryParams.SEARCH),
        'reported',
      );

      this.setState({
        totalCount: res.count,
        items: res.users as IUser[],
      });
    } catch (e) {
      console.log(e);

      // show error
      this.setState({ errorMsg: e.message });
    }

    this.props.setShowLoading(false);
  }

  onExpandItem(item: IUser) {
    const { userIdsExpanded } = this.state;
    const index = userIdsExpanded.findIndex((p) => p === item._id);
    if (index >= 0) {
      userIdsExpanded.splice(index, 1);
    } else {
      userIdsExpanded.push(item._id);
    }

    this.setState({ userIdsExpanded });
  }

  isExpanded(item: IUser) {
    return this.state.userIdsExpanded.findIndex((p) => p === item._id) >= 0;
  }

  onClickUser(user: IUser): void {
    super.onClickUser(user);

    // go to user detail page
    this.props.history.push({
      pathname: `${ROUTES.USERS}/${user._id}`,
      search: `?${queryString({ from: removeLeadingSlash(ROUTES.REPORTS_USER) })}`,
    });
  }

  async onRemoveReport(item: IUser, report: IReport) {
    this.props.setShowLoading(true);

    try {
      const { items } = this.state;

      await ApiService.deleteUserReport(item._id, report._id);

      // update list
      const i = item.reports.findIndex((r) => r._id === report._id);
      item.reports.splice(i, 1);

      this.setState({ items });
    } catch (e) {
      console.log(e);

      // show error
      this.setState({ errorMsg: e.message });
    }

    this.props.setShowLoading(false);
  }

  async onRemoveReportAll(item: IUser) {
    this.props.setShowLoading(true);

    try {
      await ApiService.deleteUserReport(item._id);

      // reload data
      this.loadData();
    } catch (e) {
      console.log(e);

      // show error
      this.setState({ errorMsg: e.message });
    }

    this.props.setShowLoading(false);
  }
}

const mapStateToProps = (state: IApplicationState) => state;

const mapDispatchToProps = {
  setShowLoading,
  setUserSelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersReported);
