import React, { Component } from 'react';
import { Publish } from '@material-ui/icons';
import { Route, RouteComponentProps, Switch, Redirect, Link } from 'react-router-dom';
import { Button, Divider } from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';

import Header from '../../components/Header/Header';
import { SideMenuItem } from '../../components/SideMenuItem/SideMenuItem';
import ROUTES from '../../constants/routes';
import { removeLeadingSlash, removeTrailingSlash } from '../../helpers/utils';
import Users from '../users/Users';
import UsersBanned from '../users/UsersBanned';
import UsersDeleted from '../users/UsersDeleted';
import PostsUser from '../posts/PostsUser';
import PostsPending from '../posts/PostsPending';
import MProgressBar from '../../components/MProgressBar/MProgressBar';
import { IApplicationState } from '../../redux/reducers';
import UserDetail from '../users/UserDetail';
import PostsApproved from '../posts/PostsApproved';
import Settings from '../settings/Settings';
import PostsReported from '../posts/PostsReported';
import Reports from '../reports/Reports';
import SideMenuSubItem from '../../components/SideMenuItem/SideMenuSubItem';
import PostDetail from '../posts/PostDetail';
import UsersReported from '../users/UsersReported';
import UploadPost from '../posts/UploadPost';
import UsersDownloadedPost from '../users/UsersDownloadedPost';
import Organizations from '../organizations/Organizations';
import './styles.scss';

type LayoutState = {
  selectedSideMenu: string | null;
};

class Layout extends Component<Props, LayoutState> {
  constructor(props: Props) {
    super(props);

    const params = new URLSearchParams(this.props?.location.search);
    this.state = {
      selectedSideMenu: params.get('from'),
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!_.isEqual(prevProps?.location, this.props?.location)) {
      const params = new URLSearchParams(this.props?.location.search);
      this.setState({
        selectedSideMenu: params.get('from'),
      });
    }
  }

  render() {
    return (
      <div className="main-app">
        {/* header */}
        <Header {...this.props} />

        <div className="flex flex-1 relative">
          <MProgressBar inProgress={this.props.uiReducer.showLoading} />

          {/* sidebar */}
          <div className="w-60 z-10 flex flex-col p-4">
            {/* upload new image */}
            <Link to={ROUTES.CREATE_IMAGE}>
              <Button
                variant="contained"
                color="primary"
                className="outline-none normal-case rounded-full px-5 py-2 text-white mb-4 w-full"
                startIcon={<Publish />}
                // onClick={() => onClickItem()}
              >
                Upload Image
              </Button>
            </Link>

            <SideMenuItem
              selected={this.isRouteMatching(ROUTES.USERS)}
              title="All Users"
              route={ROUTES.USERS}
            />
            <SideMenuItem
              selected={this.isRouteMatching(ROUTES.USERS_BANNED)}
              title="Banned Users"
              route={ROUTES.USERS_BANNED}
            />
            <SideMenuItem
              selected={this.isRouteMatching(ROUTES.USERS_DELETED)}
              title="Deleted Users"
              route={ROUTES.USERS_DELETED}
            />
            <SideMenuItem
              selected={this.isRouteMatching(ROUTES.IMAGES_PENDING)}
              title="Images Pending Approval"
              route={ROUTES.IMAGES_PENDING}
            />
            <SideMenuItem
              selected={this.isRouteMatching(ROUTES.IMAGES_APPROVED)}
              title="Images Approved"
              route={ROUTES.IMAGES_APPROVED}
            />

            <SideMenuItem selected={this.isRouteMatching(ROUTES.REPORTS, false)} title="Reports">
              <SideMenuSubItem
                title="Reported Abuse"
                route={ROUTES.REPORTS_ABUSE}
                selected={this.isRouteMatching(ROUTES.REPORTS_ABUSE)}
              />
              <SideMenuSubItem
                title="Reported Images"
                route={ROUTES.REPORTS_IMAGE}
                selected={this.isRouteMatching(ROUTES.REPORTS_IMAGE)}
              />
              <SideMenuSubItem
                title="Reported Users"
                route={ROUTES.REPORTS_USER}
                selected={this.isRouteMatching(ROUTES.REPORTS_USER)}
              />
            </SideMenuItem>

            <SideMenuItem
              selected={this.isRouteMatching(ROUTES.SETTINGS)}
              title="Settings"
              route={ROUTES.SETTINGS}
            />

            <SideMenuItem
              selected={this.isRouteMatching(ROUTES.ORGANIZATIONS)}
              title="Organizations"
              route={ROUTES.ORGANIZATIONS}
            />
          </div>

          {/* content */}
          <div className="flex-1">
            <Switch>
              <Route exact path={ROUTES.USERS} component={Users} />
              <Route path={`${ROUTES.USERS}/:id`} component={UserDetail} />
              <Route path={ROUTES.USERS_BANNED} component={UsersBanned} />
              <Route path={ROUTES.USERS_DELETED} component={UsersDeleted} />
              <Route path={`${ROUTES.USERS_DOWNLOADED_POST}/:id`} component={UsersDownloadedPost} />
              <Route path={`${ROUTES.IMAGES}/:id`} component={PostDetail} />
              <Route path={ROUTES.CREATE_IMAGE} component={UploadPost} />
              <Route path={ROUTES.IMAGES} component={PostsUser} />
              <Route path={ROUTES.IMAGES_PENDING} component={PostsPending} />
              <Route path={ROUTES.IMAGES_APPROVED} component={PostsApproved} />
              <Route path={ROUTES.SETTINGS} component={Settings} />
              <Route path={ROUTES.ORGANIZATIONS} component={Organizations} />
              <Route path={ROUTES.REPORTS_IMAGE} component={PostsReported} />
              <Route path={ROUTES.REPORTS_USER} component={UsersReported} />
              <Route path={ROUTES.REPORTS_ABUSE} component={Reports} />

              <Redirect from="/" to={ROUTES.USERS} />
            </Switch>
          </div>
        </div>

        {/* footer */}
        <div className="flex py-4 justify-center">
          {/* actions */}
          <div className="flex">
            <Button className="outline-none normal-case text-gray-400" size="small">
              Terms of User
            </Button>
            <Divider orientation="vertical" className="mx-10" />
            <Button className="outline-none normal-case text-gray-400" size="small">
              Privacy Policy
            </Button>
            <Divider orientation="vertical" className="mx-10" />
            <Button className="outline-none normal-case text-gray-400" size="small">
              Report Abuse
            </Button>
          </div>
        </div>
      </div>
    );
  }

  isRouteMatching(route: string, exactMatch = true) {
    const { location } = this.props;

    if (exactMatch) {
      if (removeLeadingSlash(route) === this.state.selectedSideMenu) {
        return true;
      }
    } else {
      if (this.state.selectedSideMenu?.startsWith(removeLeadingSlash(route))) {
        return true;
      }
    }

    if (exactMatch) {
      return route === removeTrailingSlash(location.pathname);
    } else {
      return location.pathname.startsWith(route);
    }
  }
}

interface Props extends IApplicationState, RouteComponentProps {}

const mapStateToProps = (state: IApplicationState) => state;

export default connect(mapStateToProps, null)(Layout);
