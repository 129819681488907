import React, { Component } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import IndustryLabel from '../IndustryLabel/IndustryLabel';

export default class IndustryInput extends Component<Props, States> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tagText: '',
    };
  }

  render(): React.ReactElement {
    const options = this.props.candidates.filter(
      (industry) => industry != undefined && industry != null && industry.trim() !== '',
    );
    return (
      <div className="flex flex-col flex-1">
        {/* input for new tag */}
        <Autocomplete
          freeSolo
          options={options}
          inputValue={this.state.tagText}
          noOptionsText="No tags"
          classes={{ popper: 'popper-tags' }}
          renderInput={(params) => <TextField {...params} placeholder="Add new Industry" />}
          onInputChange={(e, newInputValue, reason) => {
            if (reason === 'reset') {
              this.setState({ tagText: '' });
              return;
            } else {
              this.setState({ tagText: newInputValue });
            }
          }}
          onChange={(e, value) => {
            e.stopPropagation();
            e.preventDefault();

            if (value) {
              this.onAddTag(value);
            }
          }}
        />

        {/* tags */}
        <div className="flex flex-wrap mt-2">
          {this.props.selected.map((t, i) => (
            <IndustryLabel
              key={`tag${i}`}
              name={t}
              className="mr-2 mb-1.5"
              onClick={() => {
                if (this.props.onClickIndustryLabel) {
                  this.props.onClickIndustryLabel(t);
                }
              }}
              onRemove={() => this.onRemoveTag(i)}
            />
          ))}
        </div>
      </div>
    );
  }

  onAddTag(tag: string): void {
    const { selected } = this.props;

    if (selected.indexOf(tag) < 0) {
      selected.push(tag);

      this.props.onChangeIndustries(selected);
    }

    this.setState({ tagText: '' });
  }

  onRemoveTag(index: number): void {
    const { selected } = this.props;
    selected.splice(index, 1);

    this.props.onChangeIndustries(selected);
  }
}

interface Props {
  selected: string[];
  candidates: string[];
  onChangeIndustries: (industries: string[]) => void;
  onClickIndustryLabel?: (industry: string) => void;
}

interface States {
  tagText: string;
}
