import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

export interface SideMenuItemProps {
  title: string;
  selected?: boolean;
  route?: string;
}

export default class SideMenuItem extends Component<SideMenuItemProps> {
  static defaultProps = {
    selected: false,
  };

  render(): React.ReactNode {
    const menuItem = (
      <Button
        className={`outline-none normal-case text-gray-500 justify-start rounded-full pr-5 pl-10 py-1.5 w-full text-xs ${
          this.props.selected ? 'font-bold' : ''
        }`}
        size="small"
      >
        {this.props.title}
      </Button>
    );

    return (
      <div>
        {this.props.route ? (
          <Link className="w-full" to={this.props.route}>
            {menuItem}
          </Link>
        ) : (
          menuItem
        )}
      </div>
    );
  }
}
