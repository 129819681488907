import { SET_TOKEN, SET_USER } from '../../constants/action-types';
import { IAction } from '../reducers/types';

export const setUserInfo = (userInfo: any): IAction => {
  return {
    type: SET_USER,
    payload: userInfo,
  };
};

export const setToken = (token: string): IAction => {
  return {
    type: SET_TOKEN,
    payload: token,
  };
};
