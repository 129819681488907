import React, { Component } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Block, CloudUpload } from '@material-ui/icons';

export default class EnableUploadButton extends Component<UploadButtonProps> {
  render(): React.ReactElement {
    return (
      <Tooltip title={this.props.enabled ? 'Disable Upload' : 'Enable Upload'} placement="top">
        <IconButton aria-label="upload" className="outline-none" onClick={this.props.onClick}>
          {this.props.enabled ? (
            <CloudUpload fontSize="small" className="text-gray-400" />
          ) : (
            <>
              <CloudUpload fontSize="small" className="text-gray-300" />
              <Block className="absolute bottom-2.5 right-2 text-red-900 font-bold text-base" />
            </>
          )}
        </IconButton>
      </Tooltip>
    );
  }
}

interface UploadButtonProps {
  enabled: boolean;
  onClick: () => void;
}
