import { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { connect } from 'react-redux';

import Login from './pages/login/Login';
import { theme } from './theme';
import ROUTES from './constants/routes';
import Signup from './pages/login/Signup';
import Layout from './pages/main/Layout';
import { IApplicationState } from './redux/reducers';
import { AuthService } from './services';
import { IUser } from './models/user';
import AuthRoute from './components/AuthRoute';
import Support from './pages/policy/Support';
import Policy from './pages/policy/Policy';
import './assets/style/style.css';
import './App.scss';

interface IAppState {
  currentUser?: IUser;
}

class App extends Component<Props, IAppState> {
  constructor(props: Props) {
    super(props);

    if (!props.userReducer.user) {
      // get user from cookie
      AuthService.getCurrentUser();
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path={ROUTES.LOGIN} component={Login} />
          <Route path={ROUTES.SIGNUP} component={Signup} />
          <Route path={ROUTES.POLICY} component={Policy} />
          <Route path={ROUTES.SUPPORT} component={Support} />

          <AuthRoute component={Layout} />
        </Switch>
      </ThemeProvider>
    );
  }
}

type Props = IApplicationState;

const mapStateToProps = (state: IApplicationState) => state;

export default connect(mapStateToProps)(App);
