import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './styles.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default class Policy extends Component<RouteComponentProps> {
  render(): React.ReactNode {
    return (
      <div className="main-app">
        {/* header */}
        <Header {...this.props} showSearch={false} />

        <div className="flex flex-1 relative bg-gray-50">
          {/* content */}
          <div className="flex-1">
            <div className="flex flex-col page-policy bg-gray-50">
              <div className="dv-content mx-6 my-4 rounded-xl py-4 px-6 bg-white">
                {/* title */}
                <h1 className="text-4xl font-bold text-center my-6">Privacy Policy</h1>

                <p>
                  Kalos Services Inc./MechPic is committed to keeping any and all personal
                  information collected of those individuals that use our application and make use
                  of our online facilities and services accurate, confidential, secure, and private.
                  Our privacy policy has been designed and created to ensure that those affiliated
                  with Kalos Services Inc./MechPic are aware of our commitment and realization of
                  our obligation not only to meet but to exceed most existing privacy standards.
                </p>
                <p>
                  <b>
                    <i>THEREFORE,</i>
                  </b>{' '}
                  this Privacy Policy Agreement shall apply to Kalos Services Inc./MechPic, and thus
                  it shall govern any and all data collection and usage thereof. Through the use of
                  MechPic, you are herein consenting to the following data procedures expressed
                  within this agreement.
                </p>
                <p>
                  <b>
                    <u>Collection of Information</u>
                  </b>
                  <br />
                  This application collects various types of information, such as:
                </p>
                <p>
                  <ul>
                    <li>
                      Information automatically collected when using our application on a computer,
                      tablet, or smartphone, which may include cookies, third-party tracking
                      technologies, and server logs.
                    </li>
                    <li>
                      Information obtained from sign-in and registration forms, such as your name,
                      location, and email address.
                    </li>
                  </ul>
                </p>
                <p>
                  We may also gather information about the type of browser you are using, IP
                  address, or type of operating system to assist us in providing and maintaining
                  superior quality service.
                </p>
                <p>
                  It is highly recommended and suggested that you review the privacy policies and
                  statements of any website you choose to use or frequent as a means to better
                  understand the way in which other websites garner, make use of, and share
                  information collected.
                </p>
                <p>
                  <b>
                    <u>Use of Information Collected</u>
                  </b>
                  <br />
                  Kalos Services Inc./MechPic may collect and may make use of personal information
                  to assist in the operation of our website and to ensure delivery of the services
                  you need and request. At times, we may find it necessary to use personally
                  identifiable information as a means to keep you informed of other possible
                  educational and visual content that may be available to you from MechPic. Kalos
                  Services Inc./MechPic may also be in contact with you with regards to completing
                  surveys and/or research questionnaires related to your opinion of current or
                  potential future services that may be offered.
                </p>
                <p>
                  Kalos Services Inc./MechPic does not now, nor will it in the future, sell, rent,
                  or lease any of our customer lists and/or names to any third parties.
                </p>
                <p>
                  Kalos Services Inc./MechPic may feel it necessary, from time to time, to make
                  contact with you on behalf of other external business partners with regards to a
                  potential new offer that may be of interest to you. If you consent or show
                  interest in presented offers, then, at that time, specific identifiable
                  information, such as name, email address, and/or telephone number, may be shared
                  with the third party.
                </p>
                <p>
                  Kalos Services Inc./MechPic may deem it necessary to follow websites and/or pages
                  that our users may frequent in an effort to gleam what types of services and/or
                  products may be the most popular to customers or the general public.
                </p>
                <p>
                  Kalos Services Inc./MechPic may disclose your personal information, without prior
                  notice to you, only if required to do so in accordance with applicable laws and/or
                  in a good faith belief that such action is deemed necessary or is required in an
                  effort to:
                </p>
                <p>
                  <ul>
                    <li>
                      Remain in conformance with any decrees, laws, and/or statutes or in an effort
                      to comply with any process which may be served upon Kalos Services
                      Inc./MechPic and/or our application;
                    </li>
                    <li>
                      Maintain, safeguard and/or preserve all the rights and/or property of Kalos
                      Services Inc./MechPic; and
                    </li>
                    <li>
                      Perform under demanding conditions in an effort to safeguard the personal
                      safety of users of MechPic and/or the general public.
                    </li>
                  </ul>
                </p>
                <p>
                  <b>
                    <u>Children Under Age of 13</u>
                  </b>
                  <br />
                  Kalos Services Inc./MechPic does not knowingly collect personal identifiable
                  information from children under the age of thirteen (13) without verifiable
                  parental consent. If it is determined that such information has been inadvertently
                  collected on anyone under the age of thirteen (13), we shall immediately take the
                  necessary steps to ensure that such information is deleted from our system&quot;s
                  database. Anyone under the age of thirteen must seek and obtain parent or guardian
                  permission to use this application.
                </p>
                <p>
                  <b>
                    <u>Unsubscribe or Opt-Out</u>
                  </b>
                  <br />
                  All users and/or visitors to our application have the option to discontinue
                  receiving communication from us and/or reserve the right to discontinue receiving
                  communications by way of email or newsletters. To discontinue or unsubscribe to
                  our website please send an email that you wish to unsubscribe to
                  bryan@hvacrschool.com. If you wish to unsubscribe or opt-out from any third-party
                  websites, you must go to that specific website to unsubscribe and/or opt-out.
                </p>
                <p>
                  <b>
                    <u>Links to Other Web Sites</u>
                  </b>
                  <br />
                  Our application may contain links to affiliate and other websites or applications.
                  Kalos Services Inc./MechPic does not claim nor accept responsibility for any
                  privacy policies, practices, and/or procedures of other such websites. Therefore,
                  we encourage all users and visitors to be aware when they leave our application
                  and to read the privacy statements of each and every website or application that
                  collects personally identifiable information. The aforementioned Privacy Policy
                  Agreement applies only and solely to the information collected by our website.
                </p>
                <p>
                  <b>
                    <u>Security</u>
                  </b>
                  <br />
                  Kalos Services Inc./MechPic shall endeavor and shall take every precaution to
                  maintain adequate physical, procedural, and technical security with respect to our
                  offices and information storage facilities so as to prevent any loss, misuse,
                  unauthorized access, disclosure, or modification of the user&quot;s personal
                  information under our control.
                </p>
                <p>
                  The company also uses Secure Socket Layer (SSL) for authentication and private
                  communications in an effort to build users&quot; trust and confidence in the
                  internet application use by providing simple and secure access and communication
                  of credit card and personal information.
                </p>
                <p>
                  <b>
                    <u>Changes to Privacy Policy Agreement</u>
                  </b>
                  <br />
                  Kalos Services Inc./MechPic reserves the right to update and/or change the terms
                  of our privacy policy, and as such, we will notify users upon launching the app
                  for the first time since the changes have been implemented. Our goal is to ensure
                  that our users and/or visitors are always aware of the type of information we
                  collect, how it will be used, and under what circumstances, if any, we may
                  disclose such information. If at any point in time Kalos Services Inc./MechPic
                  decides to make use of any personally identifiable information on file, in a
                  manner vastly different from that which was stated when this information was
                  initially collected, the user or users shall be promptly notified by email. Users
                  at that time shall have the option as to whether or not to permit the use of their
                  information in this separate manner.
                </p>
                <p>
                  <b>
                    <u>Acceptance of Terms</u>
                  </b>
                  <br />
                  Through the use of the MechPic application, you are hereby accepting the terms and
                  conditions stipulated within the aforementioned Privacy Policy Agreement. If you
                  are not in agreement with our terms and conditions, then you should refrain from
                  further use of our application and affiliated sites. In addition, your continued
                  use of our application following the posting of any updates or changes to our
                  terms and conditions shall mean that you are in agreement and acceptance of such
                  changes.
                </p>
                <p>
                  <b>
                    <u>How to Contact Us</u>
                  </b>
                  <br />
                  If you have any questions or concerns regarding the Privacy Policy Agreement
                  related to our website, please feel free to contact us at the following email,
                  telephone number, or mailing address.
                </p>
                <p>
                  <b>Email:</b> bryan@hvacrschool.com
                </p>
                <p>
                  <b>Telephone Number:</b> (903)-884-8227
                </p>
                <p>
                  <b>Mailing Address:</b>
                  <br />
                  <div className="ml-6">
                    Kalos Services Inc./MechPic
                    <br />
                    236 Hatteras Ave
                    <br />
                    Clermont, Florida 34711
                  </div>
                </p>
                <p>04/22/2021</p>
              </div>
            </div>
          </div>
        </div>

        {/* footer */}
        <Footer {...this.props} />
      </div>
    );
  }
}
