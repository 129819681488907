import React, { Component } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import Tag from '../Tag/Tag';

export default class TagInput extends Component<Props, States> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tagText: '',
    };
  }

  render(): React.ReactElement {
    const options = this.props.tagsAll.filter((tag) => tag != undefined && tag != null);
    return (
      <div className="flex flex-col flex-1">
        {/* input for new tag */}
        <Autocomplete
          freeSolo
          options={options}
          inputValue={this.state.tagText}
          noOptionsText="No tags"
          classes={{ popper: 'popper-tags' }}
          renderInput={(params) => <TextField {...params} placeholder="Add new tag" />}
          onInputChange={(e, newInputValue, reason) => {
            if (reason === 'reset') {
              this.setState({ tagText: '' });
              return;
            } else {
              this.setState({ tagText: newInputValue });
            }
          }}
          onChange={(e, value) => {
            e.stopPropagation();
            e.preventDefault();

            if (value) {
              this.onAddTag(value);
            }
          }}
        />

        {/* tags */}
        <div className="flex flex-wrap mt-2">
          {this.props.tags.map((t, i) => (
            <Tag
              key={`tag${i}`}
              name={t}
              className="mr-2 mb-1.5"
              onClick={() => {
                if (this.props.onClickTag) {
                  this.props.onClickTag(t);
                }
              }}
              onRemove={() => this.onRemoveTag(i)}
            />
          ))}
        </div>
      </div>
    );
  }

  onAddTag(tag: string): void {
    const { tags } = this.props;

    if (tags.indexOf(tag) < 0) {
      tags.push(tag);

      this.props.onChangeTags(tags);
    }

    this.setState({ tagText: '' });
  }

  onRemoveTag(index: number): void {
    const { tags } = this.props;
    tags.splice(index, 1);

    this.props.onChangeTags(tags);
  }
}

interface Props {
  tags: string[];
  tagsAll: string[];
  onChangeTags: (tags: string[]) => void;
  onClickTag?: (tag: string) => void;
}

interface States {
  tagText: string;
}
