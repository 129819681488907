import React, { Component, ReactNode } from 'react';
import { Alert } from '@material-ui/lab';
import { RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import './styles.scss';
import { setShowLoading } from '../../redux/actions/actions';
import { connect } from 'react-redux';
import { IApplicationState } from '../../redux/reducers';
import { ApiService } from '../../services';
import { ISetting } from '../../models/setting';
import _ from 'lodash';
import { IIndustry } from '../../models/industry';
import { Delete, Edit, Add } from '@material-ui/icons';

interface ISettingsState {
  // ui
  errorMsg: string;
  showIndustryDialog: boolean;
  industryName: string;
  isInAction: boolean;
  errorMsgAction: string;

  setting: ISetting;

  // industry
  industries: IIndustry[];
  industryIndexDelete: number;
  industryIndexEdit: number;
}

class Settings extends Component<Props, ISettingsState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      errorMsg: '',
      errorMsgAction: '',
      showIndustryDialog: false,
      industryName: '',
      isInAction: false,

      setting: {
        // default values
        uploadEnabled: true,
      },

      industries: [],
      industryIndexDelete: -1,
      industryIndexEdit: -1,
    };
  }

  componentDidMount(): void {
    // load data
    this.loadData();
  }

  render(): React.ReactElement {
    const { setting } = this.state;

    return (
      <div className="dv-container px-4 py-3 text-sm page-settings text-gray-600">
        {/* header */}
        <div className="flex">
          {/* title */}
          <div className="pt-2 pb-6 txt-primary text-2xl font-bold">Settings</div>
        </div>

        <div className="flex flex-col">
          {/* error notice */}
          {this.state.errorMsg ? (
            <Alert
              severity="error"
              className="mb-4"
              onClose={() => this.setState({ errorMsg: '' })}
            >
              {this.state.errorMsg}
            </Alert>
          ) : null}

          <div className="dv-content flex flex-col lg:self-center">
            <div className="mb-2 text-gray-800 font-bold">Upload Images</div>
            <div className="flex flex-col py-4 px-6 bg-light-transparent rounded-lg mb-4">
              {/* disable upload images */}
              <div className="flex justify-between items-center">
                <span>Enable upload images of all users</span>
                <Switch
                  color="primary"
                  size="small"
                  checked={setting.uploadEnabled}
                  onChange={(e) => {
                    this.onChangeEnableUpload(e.target.checked);
                  }}
                />
              </div>
            </div>

            {this.renderIndustries()}
          </div>
        </div>

        {this.renderIndustryDialog()}
        {this.renderDeleteConfirmDialog()}
      </div>
    );
  }

  renderIndustries() {
    const { industries } = this.state;

    return (
      <React.Fragment>
        <div className="flex justify-between items-center mb-1">
          <div className="text-gray-800 font-bold">Industries</div>

          {/* add new */}
          <Button
            className="outline-none normal-case"
            startIcon={<Add />}
            onClick={() =>
              this.setState({
                industryIndexEdit: -1,
                showIndustryDialog: true,
                errorMsgAction: '',
                industryName: '',
              })
            }
          >
            Add New
          </Button>
        </div>

        <div className="p-2 bg-light-transparent rounded-lg dv-table-main">
          {this.renderEmptyNotice()}

          {/* list */}
          <Table size="small">
            <TableBody>
              {industries.map((item, i) => {
                const isLast = i === industries.length - 1;

                return (
                  <TableRow key={item._id}>
                    <TableCell className={`text-gray-600 ${isLast ? 'border-0' : ''}`}>
                      {item.name}
                    </TableCell>

                    {/* actions */}
                    <TableCell className={`w-28 py-0 px-2 ${isLast ? 'border-0' : ''}`}>
                      {/* edit */}
                      <Tooltip title="Edit" placement="bottom">
                        <IconButton
                          aria-label="edit"
                          className="outline-none text-gray-400"
                          onClick={() =>
                            this.setState({
                              industryIndexEdit: i,
                              showIndustryDialog: true,
                              errorMsgAction: '',
                              industryName: item.name,
                            })
                          }
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                      </Tooltip>

                      {/* delete */}
                      <Tooltip title="Delete" placement="bottom">
                        <IconButton
                          aria-label="delete"
                          className="outline-none text-gray-400"
                          onClick={() => this.setState({ industryIndexDelete: i })}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </React.Fragment>
    );
  }

  renderEmptyNotice(): ReactNode {
    if (this.props.uiReducer.showLoading || !_.isEmpty(this.state.industries)) {
      return null;
    }

    return (
      <div className="flex justify-center py-24 text-gray-400 text-sm">No industries added</div>
    );
  }

  renderIndustryDialog() {
    const {
      industryIndexEdit,
      showIndustryDialog,
      industryName,
      isInAction,
      errorMsgAction,
    } = this.state;

    return (
      <Dialog
        open={showIndustryDialog}
        onClose={() => this.onCloseDialog()}
        aria-labelledby="edit-dialog-title"
        aria-describedby="edit-dialog-description"
      >
        <DialogTitle>{industryIndexEdit < 0 ? 'Add New' : 'Edit'} Industry</DialogTitle>
        <DialogContent className="w-96">
          {/* error notice */}
          {errorMsgAction ? (
            <Alert
              severity="error"
              className="mb-4"
              onClose={() => this.setState({ errorMsgAction: '' })}
            >
              {errorMsgAction}
            </Alert>
          ) : null}

          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Industry Name"
            label="Name"
            fullWidth
            value={industryName}
            onChange={(e) => {
              this.setState({ industryName: e.target.value });
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => this.onSaveIndustry()} color="primary" disabled={isInAction}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderDeleteConfirmDialog(): ReactNode {
    return (
      <Dialog
        open={this.state.industryIndexDelete >= 0}
        onClose={() => this.onCloseDialog()}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Are you sure you want to delete this industry?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The industry will be removed permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.onDeleteIndustry()} color="secondary">
            Delete
          </Button>
          <Button onClick={() => this.onCloseDialog()} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  async loadData() {
    this.props.setShowLoading(true);

    try {
      const res = await ApiService.getSetting();

      this.setState({ setting: res.setting });

      // get all industries
      const industries = await ApiService.getIndustries();
      this.setState({ industries });
    } catch (e) {
      console.log(e);

      // show error
      if (e instanceof Error) {
        this.setState({ errorMsg: e.message });
      }
    }

    this.props.setShowLoading(false);
  }

  async onChangeEnableUpload(value: boolean) {
    const { setting } = this.state;
    setting.uploadEnabled = value;
    await this.setState({ setting });

    try {
      await ApiService.updateSetting(setting);
    } catch (e) {
      // show error
      if (e instanceof Error) {
        this.setState({ errorMsg: e.message });
      }

      // return switch back
      setting.uploadEnabled = !value;
      await this.setState({ setting });
    }
  }

  async onSaveIndustry() {
    const { industries, industryIndexEdit, industryName } = this.state;

    this.setState({ isInAction: true });

    try {
      if (industryIndexEdit >= 0) {
        const industry = industries[industryIndexEdit];
        industry.name = industryName;

        await ApiService.updateIndustry(industries[industryIndexEdit]);
      } else {
        const industry = await ApiService.createIndustry({ name: industryName });
        industries.push(industry);
      }

      // update list
      this.setState({
        industries,
      });

      this.onCloseDialog();
    } catch (e) {
      console.log(e);

      // show error
      if (e instanceof Error) {
        this.setState({ errorMsgAction: e.message });
      }
    }

    this.setState({ isInAction: false });
  }

  async onDeleteIndustry() {
    const { industries, industryIndexDelete } = this.state;

    this.props.setShowLoading(true);

    try {
      await ApiService.deleteIndustry(industries[industryIndexDelete]._id);

      // remove from list
      industries.splice(industryIndexDelete, 1);
      this.setState({ industries });
    } catch (e) {
      console.log(e);

      // show error
      if (e instanceof Error) {
        this.setState({ errorMsg: e.message });
      }
    }

    this.onCloseDialog();
    this.props.setShowLoading(false);
  }

  onCloseDialog() {
    this.setState({
      showIndustryDialog: false,
      industryIndexDelete: -1,
    });
  }
}

export interface Props extends IApplicationState, PropsFromDispatch, RouteComponentProps {}

interface PropsFromDispatch {
  setShowLoading: typeof setShowLoading;
}

const mapStateToProps = (state: IApplicationState) => state;
const mapDispatchToProps = {
  setShowLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
