import React, { Component, FormEvent } from 'react';
import IMG_LOGO from '../../assets/images/logo.png';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { COUNTRIES } from '../../constants/countries';
import { countryToFlag } from '../../helpers/utils';
import { Checkbox, FormControlLabel, TextField, Button } from '@material-ui/core';
import ROUTES from '../../constants/routes';
import { Link, RouteComponentProps } from 'react-router-dom';

export default class Signup extends Component<RouteComponentProps, States> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      agreeShare: false,
      agreeDownload: false,
    };
  }

  render(): React.ReactNode {
    return (
      <div className="flex flex-col items-center page-signup p-4">
        {/* logo */}
        <img src={IMG_LOGO} className="w-32" alt="logo" />

        {/* title */}
        <h1 className="text-2xl font-semibold text-center text-gray-500 mt-8">Sign Up</h1>

        {/* form */}
        <form
          className="py-6 px-8 dv-form mt-6 flex flex-col"
          autoComplete="off"
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <TextField label="Name" variant="outlined" />
          <TextField label="Email Address" variant="outlined" className="mt-4" type="email" />

          <div className="grid grid-cols-2 gap-4 mt-4">
            <TextField label="City" variant="outlined" />
            <TextField label="Zip Code" variant="outlined" />
          </div>

          <Autocomplete
            className="mt-4"
            options={COUNTRIES}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(option) => (
              <React.Fragment>
                <span className="mr-3">{countryToFlag(option.code)}</span>
                {option.label} ({option.code})
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                label="Choose a country"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />

          <div className="grid grid-cols-2 gap-4 mt-4">
            <TextField label="Create Password" variant="outlined" type="password" />
            <TextField label="Confirm Password" variant="outlined" type="password" />
          </div>

          <div className="mt-4 txt-primary">By Signing Up, You Must Agree to:</div>

          <FormControlLabel
            className="check-agree mt-0.5"
            control={
              <Checkbox
                checked={this.state.agreeShare}
                size="small"
                onChange={(e) => this.handleChange(e)}
                name="agreeShare"
              />
            }
            label="Only share images that they took or created themselves or have permission to share freely and that they agree that their images can be freely shared with attribution."
          />

          <FormControlLabel
            className="check-agree mt-0.5"
            control={
              <Checkbox
                checked={this.state.agreeDownload}
                size="small"
                onChange={(e) => this.handleChange(e)}
                name="agreeDownload"
              />
            }
            label="All users who download images must agree to abide by creative commons share-alike rules."
          />

          {/* actions */}
          <div className="flex flex-col self-center mt-4 items-center">
            <Button
              color="primary"
              variant="contained"
              className="outline-none normal-case rounded-full w-48 py-2 font-bold text-lg"
              type="submit"
            >
              Sign Up
            </Button>

            <div className="my-4 txt-primary">Already had an account?</div>

            <Link to={ROUTES.LOGIN}>
              <Button
                color="primary"
                variant="contained"
                className="outline-none normal-case rounded-full w-48 py-2 but-light font-bold text-lg"
              >
                Login
              </Button>
            </Link>
          </div>
        </form>
      </div>
    );
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.setState({ [event.target.name]: event.target.checked });
  }

  handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    // go to home page
    this.props.history?.replace('/');
  }
}

interface States {
  agreeShare: boolean;
  agreeDownload: boolean;
}
