import React, { useEffect, useState } from 'react';
import { Button, Collapse } from '@material-ui/core';
import { Link } from 'react-router-dom';

export interface SideMenuItemProps {
  title: string;
  selected?: boolean;
  route?: string;
  children?: React.ReactNode;
}

export const SideMenuItem: React.FC<SideMenuItemProps> = (props: SideMenuItemProps) => {
  const [selected, setSelected] = useState(props.selected);

  useEffect(() => {
    setSelected(props.selected);
  }, [props]);

  const menuItem = (
    <Button
      className={`outline-none normal-case text-gray-500 justify-start rounded-full px-5 py-2 w-full ${
        props.selected ? 'font-bold' : ''
      }`}
      size="small"
      onClick={() => onClickItem()}
    >
      {props.title}
    </Button>
  );

  const onClickItem = () => {
    // consider if has children only
    if (!props.children) {
      return;
    }

    if (props.selected) {
      return;
    }

    setSelected(!selected);
  };

  return (
    <div
      className={`mb-1.5 rounded-2xl
          ${selected && props.children ? 'bg-blue-50 pb-1.5' : ''}
          ${props.selected ? 'bg-blue-200' : ''}
        `}
    >
      {props.route ? (
        <Link className="w-full" to={props.route}>
          {menuItem}
        </Link>
      ) : (
        menuItem
      )}

      {/* sub items */}
      {props.children ? (
        <Collapse in={selected} timeout="auto" unmountOnExit>
          <div className="flex flex-col">{props.children}</div>
        </Collapse>
      ) : null}
    </div>
  );
};
