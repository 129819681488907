import Cookies from 'js-cookie';
import ApiService from './ApiService';
import { CURRENT_USER } from '../constants/storage-key';
import store from '../redux/store/createStore';
import { setUserInfo } from '../redux/actions/user';
import { UserData } from '../models/user';

class AuthService {
  async signIn(email: string, password: string, type = UserData.TYPE_ADMIN) {
    const user = await ApiService.signIn(email, password, type);

    // save user data to cookie
    Cookies.set(CURRENT_USER, JSON.stringify(user));

    // set current user to store
    store.dispatch(setUserInfo(user));
  }

  signOut() {
    // remove user data to cookie
    Cookies.remove(CURRENT_USER);

    // remove current user to store
    store.dispatch(setUserInfo(null));
  }

  getCurrentUser() {
    let user = null;
    const u = Cookies.get(CURRENT_USER);

    if (u) {
      user = JSON.parse(u);

      // set api token
      ApiService.setHeaderToken(user.apiToken);

      // set current user to store
      store.dispatch(setUserInfo(user));
    }

    return user;
  }
}

const authService = new AuthService();

export default authService;
