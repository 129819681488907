import React, { ReactNode } from 'react';
import BaseList, { IListState } from '../BaseList';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { ApiService } from '../../services';
import { IUser, User } from '../../models/user';
import { PersonOutlineOutlined } from '@material-ui/icons';
import { setUserSelected, setShowLoading, setItemSelected } from '../../redux/actions/actions';
import { IApplicationState } from '../../redux/reducers';
import { RouteComponentProps } from 'react-router-dom';

export interface IUsersState extends IListState {
  userIdDelete: string;
  userIdDeletePosts: string;
}

export default class BaseUsers<P extends Props, S extends IUsersState> extends BaseList<P, S> {
  constructor(props: P) {
    super(props);

    this.state = {
      ...this.state,
      userIdDelete: '',
      userIdDeletePosts: '',
    };
  }

  renderDeleteConfirmDialog(): ReactNode {
    return (
      <Dialog
        open={!!this.state.userIdDelete}
        onClose={() => this.onCloseDialog()}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Are you sure you want to delete this user?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleted user can be recovered in Deleted Users page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.onDeleteUser()} color="primary">
            Delete
          </Button>
          <Button onClick={() => this.onCloseDialog()} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderDeletePostsConfirmDialog(): ReactNode {
    return (
      <Dialog
        open={!!this.state.userIdDeletePosts}
        onClose={() => this.onCloseDialog()}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Are you sure you want to delete images of the user?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All images the user uploaded will be removed permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.onDeleteUserPosts()} color="primary">
            Delete
          </Button>
          <Button onClick={() => this.onCloseDialog()} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderUserItem(item: IUser): ReactNode {
    const user = new User(item);

    return (
      <div className="flex items-center txt-primary">
        <Avatar
          className="mr-2 w-9 h-9 bg-gray-300 cursor-pointer"
          src={user.photoUrl()}
          onClick={() => this.onClickUser(item)}
        >
          <PersonOutlineOutlined />
        </Avatar>
        <div
          tabIndex={0}
          className="flex items-center txt-primary justify-start"
          onClick={() => this.onClickUser(item)}
          onKeyDown={(e) => console.log(e.nativeEvent.key)}
          role="button"
        >
          {item.name}
        </div>
      </div>
    );
  }

  onClickUser(user: IUser): void {
    // this.props.setUserSelected(user);
  }

  /**
   * close confirm dialog
   */
  onCloseDialog(): void {
    this.setState({
      userIdDelete: '',
      userIdDeletePosts: '',
    });
  }

  //
  // actions
  //
  async onBanUser(index: number, isBan: boolean): Promise<void> {
    try {
      const { items } = this.state;
      await ApiService.banUser(items[index]._id, isBan);

      // update
      items[index].bannedAt = isBan ? new Date() : null;
      this.setState({ items });
    } catch (e) {
      console.log(e);

      // show error
      this.setState({ errorMsg: e.message });
    }
  }

  async onDeleteUser(): Promise<void> {
    try {
      await ApiService.deleteUser(this.state.userIdDelete, true);

      // refresh
      this.loadData();
    } catch (e) {
      console.log(e);

      // show error
      this.setState({ errorMsg: e.message });
    }

    this.onCloseDialog();
  }

  async onDeleteUserPosts(): Promise<void> {
    try {
      const user: IUser = this.state.items.find((u) => u._id === this.state.userIdDeletePosts);

      await ApiService.deletePosts(this.state.userIdDeletePosts);

      // show success
      this.setState({ successMsg: `Deleted Uploads of ${user.name} successfully` });
    } catch (e) {
      console.log(e);

      // show error
      this.setState({ errorMsg: e.message });
    }

    this.onCloseDialog();
  }

  async enableUserUpload(index: number, enabled: boolean): Promise<void> {
    try {
      const { items } = this.state;
      await ApiService.enableUserUpload(items[index]._id, enabled);

      // update
      items[index].uploadEnabled = enabled;
      this.setState({ items });
    } catch (e) {
      console.log(e);

      // show error
      this.setState({ errorMsg: e.message });
    }
  }

  async onBypassApproval(index: number): Promise<void> {
    try {
      const { items } = this.state;
      const item = items[index];
      const needApprovalNew = !item.needApproval;

      await ApiService.updateUser(item._id, {
        needApproval: needApprovalNew,
      });

      // update
      items[index].needApproval = needApprovalNew;
      this.setState({ items });
    } catch (e) {
      console.log(e);

      // show error
      this.setState({ errorMsg: e.message });
    }
  }
}

export interface Props extends IApplicationState, PropsFromDispatch, RouteComponentProps {}

interface PropsFromDispatch {
  setShowLoading: typeof setShowLoading;
  setUserSelected?: typeof setUserSelected;
  setItemSelected: typeof setItemSelected;
}
