import { ReactNode } from 'react';
import { Avatar, IconButton, List, ListItem, Switch } from '@material-ui/core';
import { Delete, PersonOutlineOutlined } from '@material-ui/icons';
import { connect } from 'react-redux';

import { Props } from '../BaseList';
import { IApplicationState } from '../../redux/reducers';
import { setItemSelected, setShowLoading } from '../../redux/actions/actions';
import { IPost, Post } from '../../models/post';
import { ApiService } from '../../services';
import BasePosts, { IPostsState } from './BasePosts';
import { QueryParams } from '../../constants/query-params';
import SortMenu from '../../components/SortMenu/SortMenu';
import { UiHelper } from '../../helpers/ui-helper';
import ROUTES from '../../constants/routes';
import { makeTag, queryString, removeLeadingSlash, string2Array } from '../../helpers/utils';
import { UserPost } from '../../components/UserPost';

class PostsPending extends BasePosts<Props, IPostsState> {
  render() {
    return (
      <div className="dv-container px-4 py-3 text-sm page-posts">
        {/* header */}
        <div className="flex justify-between items-center">
          {/* title */}
          <div className="pt-2 pb-4 txt-primary text-2xl font-bold">Images waiting Approval</div>

          {/* sort */}
          <SortMenu
            sort={this.getSortBy()}
            sortItems={[
              { value: 'similarity', label: 'Similarity' },
              { value: 'createdAt', label: 'Date Uploaded' },
            ]}
            onSort={this.onSortBy.bind(this)}
          />
        </div>

        {/* list */}
        {this.renderList('No Pending Images')}

        {this.renderDeleteConfirmDialog()}
        {this.renderImageModal()}
      </div>
    );
  }

  renderListCore(): ReactNode {
    return <List>{this.state.items.map((item: IPost, i) => this.renderItem(item, i))}</List>;
  }

  renderItem(item: IPost, i: number) {
    const post = new Post(item);

    return (
      <ListItem
        className="flex py-3 px-2 items-stretch border-b cursor-pointer hover:bg-blue-50"
        key={i.toString()}
        onClick={() => this.onClickItem(item)}
      >
        {/* image */}
        <UserPost
          post={item}
          onClick={(e) => {
            e.stopPropagation();

            this.setState({ postIdModal: item._id });
          }}
          outlined="text-6xl"
          className="w-28 h-28 bg-gray-300"
        />

        {/* description */}
        <div className="flex flex-col flex-1 mx-3 justify-between self-stretch">
          <div className="flex flex-col">
            <div className="text-base">{item.title}</div>

            {/* tags */}
            <div className="text-sm txt-primary-light">
              {item.tags.map((t) => makeTag(t)).join(' ')}
            </div>
            {/* industries */}
            <div className="text-sm text-gray-600">{string2Array(item.industry).join(', ')}</div>
            <div className="text-sm txt-primary-light">{item.address ?? ''}</div>
          </div>

          {/* uploaded user */}
          <div className="flex items-center">
            <Avatar className="mr-2 w-8 h-8 bg-gray-300" src={post.user?.photoUrl()}>
              <PersonOutlineOutlined />
            </Avatar>
            {/* user name */}
            <span className="txt-primary">{post.user?.data.name}</span>
          </div>
        </div>

        {/* actions */}
        <div className="flex flex-col justify-between text-gray-500">
          <div className="flex items-center">
            {/* approve */}
            <div className="flex items-center text-gray-500 mr-4">
              <span>Approve Image</span>
              <Switch
                color="secondary"
                size="small"
                className="ml-2"
                checked={!!item.approvedAt}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onChange={(e) => {
                  if (e.target.checked) {
                    this.onApprovePost(i);
                  }
                }}
              />
            </div>

            {/* delete */}
            <div className="flex items-center text-gray-500">
              <span>Delete Image</span>
              <IconButton
                aria-label="delete"
                className="outline-none"
                onClick={(e) => {
                  e.stopPropagation();

                  this.setState({ postIdDelete: item._id });
                }}
              >
                <Delete fontSize="small" className="text-gray-400" />
              </IconButton>
            </div>
          </div>

          {this.renderItemFooter(item, i)}
        </div>
      </ListItem>
    );
  }

  async loadData() {
    this.props.setShowLoading(true);

    const params = new URLSearchParams(this.props?.location.search);

    try {
      const res = await ApiService.getPosts(
        (this.state.page - 1) * this.pageSize,
        this.pageSize,
        UiHelper.sortOptionString(this.getSortBy()),
        undefined,
        undefined,
        params.get(QueryParams.SEARCH),
        '0',
        params.get(QueryParams.INDUSTRY) ?? undefined,
      );

      this.setState({
        totalCount: res.count,
        items: res.posts as IPost[],
      });
    } catch (e) {
      console.log(e);

      // show error
      if (e instanceof Error) {
        this.setState({ errorMsg: e.message });
      }
    }

    this.props.setShowLoading(false);
  }

  //
  // actions
  //
  async onApprovePost(index: number) {
    try {
      const { items } = this.state;
      await ApiService.approvePost(items[index]._id);

      // refresh to remove
      this.loadData();
    } catch (e) {
      console.log(e);

      // show error
      if (e instanceof Error) {
        this.setState({ errorMsg: e.message });
      }
    }
  }

  onClickItem(item: IPost) {
    super.onClickItem(item);

    // go to post detail page
    this.props.history.push({
      pathname: `${ROUTES.IMAGES}/${item._id}`,
      search: `?${queryString({ from: removeLeadingSlash(ROUTES.IMAGES_PENDING) })}`,
    });
  }
}

const mapStateToProps = (state: IApplicationState) => state;

const mapDispatchToProps = {
  setShowLoading,
  setItemSelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostsPending);
