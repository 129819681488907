import { FC, useState } from 'react';
import { Avatar } from '@material-ui/core';
import ReactPlayer from 'react-player/lazy';

import { IPost, Post } from '../../models/post';
import { ImageOutlined } from '@material-ui/icons';

interface Props {
  post?: IPost;
  className?: string;
  outlined?: string;
  onClick: (e: any) => void;
}

export const UserPost: FC<Props> = (props: Props) => {
  const {
    post,
    onClick,
    outlined = 'text-9xl',
    className = 'avatar-img bg-gray-300 mr-4 cursor-pointer',
  } = props;
  const [playIndex, setPlayIndex] = useState<number>(0);
  const mPost = post ? new Post(post) : null;
  const videos = mPost?.videoUrls() ?? [];

  return post && post.videoFileNames && post.videoFileNames.length > 0 ? (
    <ReactPlayer
      url={videos[playIndex]}
      className={className}
      controls
      onEnded={() => {
        if (playIndex < videos.length - 1) {
          setPlayIndex((prev: number) => prev + 1);
        } else {
          setPlayIndex(0);
        }
      }}
    />
  ) : (
    <Avatar className={className} src={mPost?.imageUrl()} variant="rounded" onClick={onClick}>
      <ImageOutlined className={outlined} />
    </Avatar>
  );
};
