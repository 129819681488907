import store from '../redux/store/createStore';
import { setToken } from '../redux/actions/user';
import Axios from 'axios';
import API from '../helpers/api';
import { ISetting } from '../models/setting';
import { ITag } from '../models/tag';
import { IPost } from '../models/post';
import { extractFileExt, extractFileName } from '../helpers/utils';
import { IIndustry } from '../models/industry';
import { IOrganization } from '../models/organization';

const TOKEN_UPLOAD = '@mechpick/upload-token';

class ApiService {
  baseUrl = `${process.env.REACT_APP_SERVER_URL}/api/v1`;

  urlFileBase = `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com`;

  // cancel token
  cancelSource = null;

  //
  // rest apis
  //

  setHeaderToken(token: string) {
    store.dispatch(setToken(token));
  }

  async signIn(email: string, password: string, type: string) {
    const params = { email, password, type };

    try {
      const { data } = await Axios.post(`${this.baseUrl}/login`, params, {});
      console.log(data);

      const user = data.user;

      user.apiToken = data.token;
      this.setHeaderToken(data.token);

      return Promise.resolve(user);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  //
  // users
  //
  async getUsers(
    from = 0,
    count = 10,
    sort = '',
    search?: string | null,
    status?: 'banned' | 'deleted' | 'reported',
  ) {
    try {
      const params: any = {
        from,
        count,
        sort,
      };

      if (status) {
        params.status = status;
      }
      if (search) {
        params.search = search;
      }

      const { data } = await API.get('/users', { params });
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async getUsersDownloadedPost(postId: string) {
    try {
      const { data } = await API.get(`/posts/downloaded/${postId}`, {});
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async getUserById(id: string) {
    try {
      const { data } = await API.get(`/users/${id}`);
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  /**
   * ban/unban user
   * @param userId
   * @param isBan
   */
  async banUser(userId: string, isBan: boolean) {
    try {
      const params = { ban: isBan ? '1' : '0' };
      const { data } = await API.post(`/users/ban/${userId}`, params);
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  /**
   * delete/recover user
   * @param userId
   * @param isDelete
   */
  async deleteUser(userId: string, isDelete: boolean) {
    try {
      const params = { delete: isDelete ? '1' : '0' };
      const { data } = await API.post(`/users/delete/${userId}`, params);
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async enableUserUpload(userId: string, enable: boolean) {
    try {
      const params = { enabled: enable ? '1' : '0' };
      const { data } = await API.post(`/users/enableUpload/${userId}`, params);
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async updateUser(userId: string, values: any) {
    try {
      const { data } = await API.post('/users/update', {
        userId,
        ...values,
      });
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async deleteUserReport(userId: string, reportId?: string) {
    try {
      const { data } = await API.delete(`/users/report/${userId}`, {
        data: { reportId: reportId },
      });
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  //
  // posts
  //
  async getPosts(
    from = 0,
    count = 10,
    sort = '',
    user?: string,
    tag?: string,
    search?: string | null,
    approved?: string,
    industry?: string,
    reported = false,
  ) {
    try {
      const params: any = {
        from,
        count,
        sort,
        reported: reported ? '1' : '0',
      };

      if (user) {
        params.user = user;
      }
      if (search) {
        params.search = search;
      }
      if (approved) {
        params.approved = approved;
      }
      if (tag) {
        params.tag = tag;
      }
      if (industry) {
        params.industry = industry;
      }

      const { data } = await API.get('/posts', { params });
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response.data);
    }
  }

  async getPostById(id: string) {
    try {
      const { data } = await API.get(`/posts/${id}`);
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async approvePost(postId: string) {
    try {
      const { data } = await API.post(`/posts/approve/${postId}`);
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async recommendPost(postId: string, isRecommend: boolean) {
    try {
      const params = { recommend: isRecommend ? '1' : '0' };
      const { data } = await API.post(`/posts/recommend/${postId}`, params);
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async deletePosts(userId: string) {
    try {
      const params = {
        user: userId,
      };
      const { data } = await API.delete(`/posts`, { data: params });
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async deletePost(postId: string) {
    try {
      const { data } = await API.delete(`/posts/${postId}`);
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async createPost(post: any) {
    try {
      const { data } = await API.post(`/posts/new`, post);
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async updatePost(post: IPost) {
    try {
      const { data } = await API.post(`/posts/update/${post._id}`, post);
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async deletePostReport(postId: string, reportId?: string) {
    try {
      const { data } = await API.delete(`/posts/report/${postId}`, {
        data: { reportId: reportId },
      });
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  //
  // tags
  //
  async getTags() {
    try {
      const { data } = await API.get('/posts/tags', {});
      console.log(data);

      return Promise.resolve((data as ITag[]).map((t) => t.name));
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response.data);
    }
  }

  //
  // reports
  //
  async getReports(from = 0, count = 10, sort = '', search?: string | null) {
    try {
      const params: any = {
        from,
        count,
        sort,
      };

      if (search) {
        params.search = search;
      }

      const { data } = await API.get('/reports', { params });
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response.data);
    }
  }

  //
  // industries
  //
  async getIndustries() {
    try {
      const { data } = await API.get('/industries', {});
      console.log(data);

      return Promise.resolve(data as IIndustry[]);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response.data);
    }
  }

  async createIndustry(industry: any) {
    try {
      const { data } = await API.post(`/industries/new`, industry);
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async updateIndustry(industry: IIndustry) {
    try {
      const { data } = await API.post(`/industries/update/${industry._id}`, industry);
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async deleteIndustry(industryId: string) {
    try {
      const { data } = await API.delete(`/industries/${industryId}`);
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  //
  // setting
  //
  async getSetting() {
    try {
      const { data } = await API.get('/settings', {});
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async updateSetting(setting: ISetting) {
    try {
      const { data } = await API.post('/settings', setting);
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async getOrganizations(): Promise<IOrganization[]> {
    try {
      const { data } = await API.get('/organization', {});

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async createOrganization(label: string) {
    try {
      const { data } = await API.post('/organization', { label });

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async updateOrganization(updated: IOrganization) {
    try {
      const { data } = await API.patch(`/organization/${updated._id}`, updated);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async deleteOrganization({ _id }: IOrganization) {
    try {
      const { data } = await API.delete(`/organization/${_id}`);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async assignOrganization(userId: string, orgId: string) {
    try {
      const { data } = await API.post(`/users/assignOrganization/${userId}`, { orgId });

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  //
  //  shorten long url to short one using bitly
  //

  async shortenUrl(origin: string): Promise<string> {
    try {
      const { data } = await Axios.post(
        'https://api-ssl.bitly.com/v4/shorten',
        {
          domain: 'bit.ly',
          group_guid: 'Bl7kkJOoXY7',
          long_url: origin,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer 20de5a333d4922fb6a17b639990569cd42443512',
          },
        },
      );

      return data.link;
    } catch (e) {
      console.log(e);

      return Promise.reject(e);
    }
  }

  //
  // s3
  //
  async getUploadUrl(params: any) {
    try {
      // generate unique file name
      const uniqueSuffix = `-${Date.now()}`;

      const name = extractFileName(params.fileName);
      const ext = extractFileExt(params.fileName);

      let fileName = `${name}${uniqueSuffix}`;
      if (ext) {
        fileName += `.${ext}`;
      }

      const { data } = await Axios.post(
        `${this.baseUrl}/s3/upload`,
        {
          ...params,
          fileName,
        },
        {
          headers: {
            token: TOKEN_UPLOAD,
          },
        },
      );

      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }

  async uploadFile(file: any, path: string, fileName?: string) {
    const res = await this.getUploadUrl({
      path: path,
      fileName: fileName ? fileName : file.name,
      fileType: file.type,
    });
    const uploadUrl = res.uploadUrl;

    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };

    const uploadRes = await Axios.put(uploadUrl, file, options);
    console.log('file upload: ', uploadRes);

    return Promise.resolve(res.fileName);
  }

  //
  // Comments
  //
  async getComments(postId: string, withUser = true) {
    try {
      const { data } = await API.get('/comment', {
        params: {
          from: 0,
          count: 0,
          postId,
          withUser,
        },
      });
      console.log(data);

      return Promise.resolve(data);
    } catch (e) {
      console.log(e);

      return Promise.reject(e.response ? e.response.data : e);
    }
  }
}

const apiService = new ApiService();

export default apiService;
