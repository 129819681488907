import { Component, FormEvent } from 'react';
import { Alert } from '@material-ui/lab';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link, RouteComponentProps } from 'react-router-dom';

import IMG_LOGO from '../../assets/images/logo.png';
import ROUTES from '../../constants/routes';
import { setShowLoading } from '../../redux/actions/actions';
import MProgressBar from '../../components/MProgressBar/MProgressBar';
import { IApplicationState } from '../../redux/reducers';
import AuthService from '../../services/AuthService';
import './styles.scss';

class Login extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // ui
      errorMsg: '',

      // data
      email: '',
      password: '',
    };
  }

  render() {
    return (
      <div className="main-app relative">
        <MProgressBar inProgress={this.props.uiReducer.showLoading} />

        <div className="flex flex-col items-center page-signup p-4">
          {/* logo */}
          <img src={IMG_LOGO} className="w-32" alt="logo" />

          {/* title */}
          <h1 className="text-2xl font-semibold text-center text-gray-500 mt-8">Login</h1>

          {/* form */}
          <form
            className="py-6 px-8 dv-form mt-6 flex flex-col"
            autoComplete="off"
            onSubmit={(e) => this.handleSubmit(e)}
          >
            {/* error notice */}
            {this.state.errorMsg ? (
              <Alert
                severity="error"
                className="mb-6"
                onClose={() => this.setState({ errorMsg: '' })}
              >
                {this.state.errorMsg}
              </Alert>
            ) : null}

            <TextField
              label="Email Address"
              variant="outlined"
              type="email"
              required
              value={this.state.email}
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
            />
            <TextField
              label="Password"
              variant="outlined"
              className="mt-4"
              type="password"
              required
              value={this.state.password}
              onChange={(e) => {
                this.setState({ password: e.target.value });
              }}
            />
            <Button
              color="primary"
              className="self-start outline-none mt-2 normal-case"
              size="small"
            >
              Forget Password?
            </Button>

            {/* actions */}
            <div className="flex flex-col self-center mt-4 items-center">
              <Button
                color="primary"
                variant="contained"
                className="outline-none normal-case rounded-full w-48 py-2 but-light font-bold text-lg"
                type="submit"
              >
                Login
              </Button>

              <div className="my-4 txt-primary">New to this website? Create Account</div>

              <Link to={ROUTES.SIGNUP}>
                <Button
                  color="primary"
                  variant="contained"
                  className="outline-none normal-case rounded-full w-48 py-2 font-bold text-lg"
                >
                  Sign Up
                </Button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    );
  }

  async handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    this.props.setShowLoading(true);

    try {
      await AuthService.signIn(this.state.email, this.state.password);

      // go to home page
      this.props.history?.replace('/');
    } catch (e) {
      // show error
      if (e instanceof Error) {
        this.setState({ errorMsg: e.message });
      }
    }

    this.props.setShowLoading(false);
  }
}

interface States {
  errorMsg: string;
  email: string;
  password: string;
}

interface Props extends IApplicationState, PropsFromDispatch, RouteComponentProps {}

interface PropsFromDispatch {
  setShowLoading: typeof setShowLoading;
}

const mapStateToProps = (state: IApplicationState) => state;
const mapDispatchToProps = {
  setShowLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
