// menu reducer
export const SET_ACTIVE_SIDEMENU = 'SET_ACTIVE_SIDEMENU';

// user reducer
export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';

// ui reducer
export const SET_SHOW_LOADING = 'SET_SHOW_LOADING';
export const SET_SEARCH_KEYWORD = 'SET_SEARCH_KEYWORD';

// data reducer
export const SET_TABLE_DATA = 'SET_TABLE_DATA';
export const SET_ITEM_SELECTED = 'SET_ITEM_SELECTED';

export const SET_USER_SELECTED = 'SET_USER_SELECTED';
