import React, { Component, FormEvent } from 'react';
import { Button, TextField } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import './styles.scss';
import { Alert } from '@material-ui/lab';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export interface ISupportState {
  // ui
  errorMsg: string;
  successMsg: string;
}

export default class Support extends Component<RouteComponentProps, ISupportState> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      errorMsg: '',
      successMsg: '',
    };
  }

  render(): React.ReactNode {
    return (
      <div className="main-app">
        {/* header */}
        <Header {...this.props} showSearch={false} />

        <div className="flex flex-1 relative bg-gray-50">
          {/* content */}
          <div className="flex-1">
            <div className="flex flex-col items-center page-policy p-4">
              {this.state.successMsg ? (
                <Alert severity="success" onClose={() => this.setState({ successMsg: '' })}>
                  {this.state.successMsg}
                </Alert>
              ) : null}

              {/* error notice */}
              {this.state.errorMsg ? (
                <Alert severity="error" onClose={() => this.setState({ errorMsg: '' })}>
                  {this.state.errorMsg}
                </Alert>
              ) : null}

              {/* title */}
              <h1 className="text-2xl font-semibold text-center text-gray-800 mt-8">Contact Us</h1>

              {/* form */}
              <form
                className="py-6 px-8 dv-form mt-6 flex flex-col dv-content rounded-xl w-96 bg-white"
                autoComplete="off"
                onSubmit={(e) => this.handleSubmit(e)}
              >
                <TextField label="Title" variant="outlined" className="mb-4" required />
                <TextField label="Content" multiline variant="outlined" rows={6} required />

                {/* actions */}
                <div className="flex flex-col self-center mt-6 items-center">
                  <Button
                    color="primary"
                    variant="contained"
                    className="outline-none normal-case rounded-full w-48 py-2 text-base"
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* footer */}
        <Footer {...this.props} />
      </div>
    );
  }

  handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    // show success notice
    this.setState({
      successMsg: 'Your request has been sent successfully',
    });
  }
}
