import React, { Component } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Favorite, FavoriteBorder } from '@material-ui/icons';

export default class LikeButton extends Component<LikeButtonProps> {
  render(): React.ReactElement {
    return (
      <Tooltip
        title={this.props.liked ? 'Cancel Bypass Approval' : 'Bypass Approval'}
        placement="top"
      >
        <IconButton aria-label="upload" className="outline-none" onClick={this.props.onClick}>
          {this.props.liked ? (
            <Favorite fontSize="small" className="text-red-600" />
          ) : (
            <FavoriteBorder fontSize="small" className="text-gray-300" />
          )}
        </IconButton>
      </Tooltip>
    );
  }
}

interface LikeButtonProps {
  liked: boolean;
  onClick: () => void;
}
