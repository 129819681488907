import React, { Component } from 'react';
import { Button, ButtonGroup, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { ArrowDropDown, Check } from '@material-ui/icons';
import { faSortAmountDown, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class SortMenu extends Component<Props, States> {
  private sortRef: Element | null | undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      opened: false,
    };
  }

  render(): React.ReactElement {
    return (
      <>
        <ButtonGroup variant="text" ref={(ref) => (this.sortRef = ref)} aria-label="split button">
          <Button
            className="outline-none normal-case pl-2 pr-1 py-1 txt-primary flex items-center border-r-0"
            onClick={() => this.onButSort()}
          >
            {this.props.sort ? (
              <>
                <FontAwesomeIcon
                  icon={this.props.sort?.asc ? faSortAmountDownAlt : faSortAmountDown}
                  className="mr-2"
                />
                {this.props.sortItems?.find((i) => i.value === this.props.sort?.by)?.label}
              </>
            ) : (
              'Sort'
            )}
          </Button>
          <Button
            className="outline-none p-0 min-w-0"
            onClick={() => this.setState({ opened: true })}
          >
            <ArrowDropDown className="text-base" />
          </Button>
        </ButtonGroup>

        <Menu
          className="menu-sort"
          anchorEl={this.sortRef}
          keepMounted
          elevation={2}
          open={this.state.opened}
          onClose={() => this.onClose()}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {this.props.sortItems?.map((item, i) => (
            <MenuItem key={i.toString()} onClick={() => this.onSortItem(item)}>
              <ListItemIcon>
                {item.value === this.props.sort?.by ? <Check fontSize="small" /> : null}
              </ListItemIcon>
              <ListItemText className="text-sm" primary={item.label} />
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }

  onButSort(): void {
    if (this.props.sort) {
      // switch asc/desc
      if (this.props.onSort) {
        this.props.onSort({ by: this.props.sort.by, asc: !this.props.sort.asc });
      }
    } else {
      // if no item selected, open menu
      this.setState({ opened: true });
    }
  }

  onSortItem(item: ISortItem): void {
    if (this.props.sort?.by === item.value) {
      // already selected
      return;
    }

    if (this.props.onSort) {
      this.props.onSort({ by: item.value, asc: false });
    }

    this.onClose();
  }

  onClose(): void {
    this.setState({ opened: false });
  }
}

interface States {
  opened: boolean;
}

interface Props {
  className?: string;
  sort?: ISortOption;
  sortItems?: { value: string; label: string }[];
  onSort?: (option: ISortOption) => void;
}

interface ISortItem {
  value: string;
  label: string;
}

export interface ISortOption {
  by: string;
  asc: boolean;
}
