import React, { Component } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

export interface MProgressBarProps {
  className?: string;
  inProgress: boolean;
}

export default class MProgressBar extends Component<MProgressBarProps> {
  static defaultProps = { className: 'sticky top-0' };

  render(): React.ReactNode {
    const classHidden = this.props.inProgress ? '' : 'hidden';

    return (
      <div className={`absolute w-full h-full bg-blue-200 bg-opacity-20 z-10 ${classHidden}`}>
        <LinearProgress color="secondary" className={this.props.className} />
      </div>
    );
  }
}
