import {
  SET_ITEM_SELECTED,
  SET_SHOW_LOADING,
  SET_USER_SELECTED,
} from '../../constants/action-types';
import { IAction } from './types';
import { IUser } from '../../models/user';

export interface UiPropsState {
  showLoading: boolean;
  searchKeyword: string;

  // page data
  userSelected: IUser;
  itemSelected: any;
}

const initialState = {
  showLoading: false,
  searchKeyword: '',

  // page data
  userSelected: null,
  itemSelected: null,
};

export const reducer = (state = initialState, action: IAction): any => {
  switch (action.type) {
    case SET_SHOW_LOADING:
      return {
        ...state,
        showLoading: action.payload,
      };

    //
    // page data
    //
    case SET_USER_SELECTED:
      return {
        ...state,
        userSelected: action.payload,
      };

    case SET_ITEM_SELECTED:
      return {
        ...state,
        itemSelected: action.payload,
      };

    default:
      return state;
  }
};
