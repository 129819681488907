import {
  SET_ITEM_SELECTED,
  SET_SEARCH_KEYWORD,
  SET_SHOW_LOADING,
  SET_TABLE_DATA,
  SET_USER_SELECTED,
} from '../../constants/action-types';
import { IAction } from '../reducers/types';

// ui reducer
export const setShowLoading = (showLoading: boolean): IAction => {
  return {
    type: SET_SHOW_LOADING,
    payload: showLoading,
  };
};
export const setSearchKeyword = (keyword: string): IAction => {
  return {
    type: SET_SEARCH_KEYWORD,
    payload: keyword,
  };
};

// data reducer
export const setTableData = (data: any): IAction => {
  return {
    type: SET_TABLE_DATA,
    payload: data,
  };
};

export const setItemSelected = (item: any): IAction => {
  return {
    type: SET_ITEM_SELECTED,
    payload: item,
  };
};

export const setUserSelected = (user: any): IAction => {
  return {
    type: SET_USER_SELECTED,
    payload: user,
  };
};
