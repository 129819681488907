import React, { Component } from 'react';
import { Close } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { makeTag } from '../../helpers/utils';

export default class Tag extends Component<Props> {
  render(): React.ReactElement {
    return (
      <div
        className={`flex items-center bg-blue-200 pl-3 pr-1 py-0.5 rounded-full cursor-pointer ${
          this.props.className ?? ''
        }`}
        onClick={this.props.onClick}
        onKeyDown={(e) => console.log(e.nativeEvent.key)}
        tabIndex={0}
        role="button"
      >
        {/* name */}
        <div className="text-sm">{makeTag(this.props.name)}</div>

        {/* close button */}
        <Button
          className="outline-none p-1 min-w-0 rounded-full ml-1"
          onClick={(e) => {
            e.stopPropagation();

            if (this.props.onRemove) {
              this.props.onRemove();
            }
          }}
        >
          <Close className="text-base" />
        </Button>
      </div>
    );
  }
}

interface Props {
  name: string;
  className?: string;
  onClick?: () => void;
  onRemove?: () => void;
}
