import React from 'react';
import { Switch, TableCell, TableRow, Button } from '@material-ui/core';
import { queryString, removeLeadingSlash } from '../../helpers/utils';
import './styles.scss';
import ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
import { IApplicationState } from '../../redux/reducers';
import { setShowLoading, setUserSelected } from '../../redux/actions/actions';
import { connect } from 'react-redux';
import { ApiService } from '../../services';
import { IUser } from '../../models/user';
import BaseUsers, { IUsersState, Props } from './BaseUsers';
import { QueryParams } from '../../constants/query-params';

class UsersDeleted extends BaseUsers<Props, IUsersState> {
  render() {
    return (
      <div className="dv-container px-4 py-3 text-sm page-users">
        {/* header */}
        <div className="flex">
          {/* title */}
          <div className="pt-2 pb-4 txt-primary text-2xl font-bold">Deleted Users</div>
        </div>

        {/* list */}
        {this.renderList('No deleted users')}
      </div>
    );
  }

  renderItem(item: IUser, i: number) {
    return (
      <TableRow key={i.toString()}>
        {/* user info */}
        <TableCell className="border-0">{this.renderUserItem(item)}</TableCell>

        {/* ban */}
        <TableCell className="border-0">
          <div className="flex items-center text-gray-500">
            <span>Ban User</span>
            <Switch
              color="secondary"
              size="small"
              className="ml-2"
              checked={!!item.bannedAt}
              onChange={(e) => {
                this.onBanUser(i, e.target.checked);
              }}
            />
          </div>
        </TableCell>

        {/* restore */}
        <TableCell className="border-0">
          <div className="flex items-center text-gray-500">
            <span>Restore</span>
            <Switch
              color="secondary"
              size="small"
              className="ml-2"
              onChange={(e) => {
                if (e.target.checked) {
                  this.onRestoreUser(i);
                }
              }}
            />
          </div>
        </TableCell>

        {/* uploads */}
        <TableCell className="border-0">
          <Link
            to={{
              pathname: ROUTES.IMAGES,
              search: `?${queryString({
                user: item._id,
                from: removeLeadingSlash(ROUTES.USERS_DELETED),
              })}`,
            }}
          >
            <Button
              color="primary"
              className="self-start outline-none normal-case txt-primary-light"
              size="small"
            >
              {item.postsCount} Uploads
            </Button>
          </Link>
        </TableCell>
      </TableRow>
    );
  }

  async loadData() {
    this.props.setShowLoading(true);

    const params = new URLSearchParams(this.props?.location.search);

    try {
      const res = await ApiService.getUsers(
        (this.state.page - 1) * this.pageSize,
        this.pageSize,
        '',
        params.get(QueryParams.SEARCH),
        'deleted',
      );

      this.setState({
        totalCount: res.count,
        items: res.users as IUser[],
      });
    } catch (e) {
      console.log(e);

      // show error
      this.setState({ errorMsg: e.message });
    }

    this.props.setShowLoading(false);
  }

  async onRestoreUser(index: number) {
    try {
      const { items } = this.state;
      await ApiService.deleteUser(items[index]._id, false);

      // refresh to remove
      this.loadData();
    } catch (e) {
      console.log(e);

      // show error
      this.setState({ errorMsg: e.message });
    }
  }

  onClickUser(user: IUser): void {
    super.onClickUser(user);

    // go to user detail page
    this.props.history.push({
      pathname: `${ROUTES.USERS}/${user._id}`,
      search: `?${queryString({ from: removeLeadingSlash(ROUTES.USERS_DELETED) })}`,
    });
  }
}

const mapStateToProps = (state: IApplicationState) => state;

const mapDispatchToProps = {
  setShowLoading,
  setUserSelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersDeleted);
