import React, { Component, ReactNode } from 'react';
import { IApplicationState } from '../redux/reducers';
import { RouteComponentProps } from 'react-router-dom';
import { setItemSelected, setShowLoading } from '../redux/actions/actions';
import { config } from '../helpers/config';
import { QueryParams } from '../constants/query-params';
import _ from 'lodash';
import { Alert, Pagination } from '@material-ui/lab';
import { Table, TableBody } from '@material-ui/core';
import { IUser } from '../models/user';
import { makeEmptyArray } from '../helpers/utils';
import { ISortOption } from '../components/SortMenu/SortMenu';
import { UiHelper } from '../helpers/ui-helper';

export interface IListState {
  // ui
  errorMsg: string;
  successMsg: string;

  // data
  totalCount: number;
  items: any[];
  page: number;
}

export default class BaseList<P extends Props, S extends IListState> extends Component<P, S> {
  pageSize = config.pageSize;
  queryParams: URLSearchParams;

  constructor(props: P) {
    super(props);

    //
    // parse query params
    //
    this.queryParams = new URLSearchParams(props.location.search);

    this.state = {
      // ui
      errorMsg: '',
      successMsg: '',

      // data
      totalCount: -1,
      items: makeEmptyArray(0),
      page: parseInt(this.queryParams.get(QueryParams.PAGE) ?? '1', 10),
    } as S;
  }

  componentDidMount(): void {
    // load data
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!_.isEqual(prevProps.location, this.props.location)) {
      // location updated, query params
      this.queryParams = new URLSearchParams(this.props.location.search);
      this.setState({ page: parseInt(this.queryParams.get(QueryParams.PAGE) ?? '1', 10) }, () => {
        this.loadData();
      });
    }
  }

  renderList(emptyNotice: string): ReactNode {
    return (
      <div className="flex flex-col">
        {/* success notice */}
        {this.state.successMsg ? (
          <Alert
            severity="success"
            className="mb-4"
            onClose={() => this.setState({ successMsg: '' })}
          >
            {this.state.successMsg}
          </Alert>
        ) : null}

        {/* error notice */}
        {this.state.errorMsg ? (
          <Alert severity="error" className="mb-4" onClose={() => this.setState({ errorMsg: '' })}>
            {this.state.errorMsg}
          </Alert>
        ) : null}

        <div className="p-2 bg-light-transparent rounded-lg dv-table-main">
          {this.renderEmptyNotice(emptyNotice)}

          {this.renderListCore()}
        </div>

        {/* pagination */}
        {this.renderPagination()}
      </div>
    );
  }

  renderListCore(): ReactNode {
    return (
      <Table size="small">
        <TableBody>{this.state.items.map((item: IUser, i) => this.renderItem(item, i))}</TableBody>
      </Table>
    );
  }

  renderPagination(): ReactNode {
    if (this.state.totalCount <= 0) {
      return null;
    }

    return (
      <Pagination
        count={Math.ceil(this.state.totalCount / this.pageSize)}
        className="pgn-main mt-3 self-end"
        page={this.state.page}
        onChange={(event, page) => {
          if (this.state.page === page) {
            // same page, do nothing
            return;
          }

          // update query params
          this.queryParams.set(QueryParams.PAGE, page.toString());

          // reload page with selected page
          this.props.history.push(`${this.props.location.pathname}?${this.queryParams.toString()}`);
        }}
      />
    );
  }

  renderEmptyNotice(text: string): ReactNode {
    if (this.props.uiReducer.showLoading || !_.isEmpty(this.state.items)) {
      return null;
    }

    return <div className="flex justify-center py-24 text-gray-400 text-sm">{text}</div>;
  }

  renderItem(item: any, i: number): void {
    // to be overridden
  }

  loadData(): void {
    // to be overridden
  }

  onSortBy(option: ISortOption): void {
    // update query params
    this.queryParams.set(QueryParams.SORT, UiHelper.sortOptionString(option));

    // reload page with selected page
    this.props.history.push(`${this.props.location.pathname}?${this.queryParams.toString()}`);
  }

  getSortBy(): ISortOption | undefined {
    const strSort = this.queryParams.get(QueryParams.SORT);

    if (strSort) {
      return { by: strSort.substring(1), asc: !strSort.startsWith('-') };
    }

    // default
    return { by: 'createdAt', asc: false };
  }
}

export interface Props extends IApplicationState, PropsFromDispatch, RouteComponentProps {}

interface PropsFromDispatch {
  setShowLoading: typeof setShowLoading;
  setItemSelected: typeof setItemSelected;
}
