import React, { Component, ReactNode } from 'react';
import { Alert } from '@material-ui/lab';
import { RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import './styles.scss';
import { setShowLoading } from '../../redux/actions/actions';
import { connect } from 'react-redux';
import { IApplicationState } from '../../redux/reducers';
import { ApiService } from '../../services';
import _ from 'lodash';
import { Delete, Edit, Add } from '@material-ui/icons';
import { IOrganization } from '../../models/organization';

interface ISettingsState {
  // ui
  errorMsg: string;
  showOrganizationDialog: boolean;
  organizationName: string;
  isInAction: boolean;
  errorMsgAction: string;

  // industry
  organizations: IOrganization[];
  organizationIndexDelete: number;
  organizationIndexEdit: number;
}

class Organizations extends Component<Props, ISettingsState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      errorMsg: '',
      errorMsgAction: '',
      showOrganizationDialog: false,
      organizationName: '',
      isInAction: false,

      organizations: [],
      organizationIndexDelete: -1,
      organizationIndexEdit: -1,
    };
  }

  componentDidMount(): void {
    // load data
    this.loadData();
  }

  render(): React.ReactElement {
    return (
      <div className="dv-container px-4 py-3 text-sm page-settings text-gray-600">
        {/* header */}
        <div className="flex">
          {/* title */}
          <div className="pt-2 pb-6 txt-primary text-2xl font-bold">Organizations</div>
        </div>

        <div className="flex flex-col">
          {/* error notice */}
          {this.state.errorMsg ? (
            <Alert
              severity="error"
              className="mb-4"
              onClose={() => this.setState({ errorMsg: '' })}
            >
              {this.state.errorMsg}
            </Alert>
          ) : null}

          <div className="dv-content flex flex-col lg:self-center">{this.renderIndustries()}</div>
        </div>

        {this.renderOrganizationDialog()}
        {this.renderDeleteConfirmDialog()}
      </div>
    );
  }

  renderIndustries() {
    const { organizations } = this.state;

    return (
      <React.Fragment>
        <div className="flex justify-between items-center mb-1">
          <div className="text-gray-800 font-bold">Organizations</div>

          {/* add new */}
          <Button
            className="outline-none normal-case"
            startIcon={<Add />}
            onClick={() =>
              this.setState({
                organizationIndexEdit: -1,
                showOrganizationDialog: true,
                errorMsgAction: '',
                organizationName: '',
              })
            }
          >
            Add New
          </Button>
        </div>

        <div className="p-2 bg-light-transparent rounded-lg dv-table-main">
          {this.renderEmptyNotice()}

          {/* list */}
          <Table size="small">
            <TableBody>
              {organizations.map((item, i) => {
                const isLast = i === organizations.length - 1;

                return (
                  <TableRow key={item._id}>
                    <TableCell className={`text-gray-600 ${isLast ? 'border-0' : ''}`}>
                      {item.label}
                    </TableCell>

                    {/* actions */}
                    <TableCell className={`w-28 py-0 px-2 ${isLast ? 'border-0' : ''}`}>
                      {/* edit */}
                      <Tooltip title="Edit" placement="bottom">
                        <IconButton
                          aria-label="edit"
                          className="outline-none text-gray-400"
                          onClick={() =>
                            this.setState({
                              organizationIndexEdit: i,
                              showOrganizationDialog: true,
                              errorMsgAction: '',
                              organizationName: item.label,
                            })
                          }
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                      </Tooltip>

                      {/* delete */}
                      <Tooltip title="Delete" placement="bottom">
                        <IconButton
                          aria-label="delete"
                          className="outline-none text-gray-400"
                          onClick={() => this.setState({ organizationIndexDelete: i })}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </React.Fragment>
    );
  }

  renderEmptyNotice(): ReactNode {
    if (this.props.uiReducer.showLoading || !_.isEmpty(this.state.organizations)) {
      return null;
    }

    return (
      <div className="flex justify-center py-24 text-gray-400 text-sm">No Organizations added</div>
    );
  }

  renderOrganizationDialog() {
    const {
      organizationIndexEdit,
      showOrganizationDialog,
      organizationName,
      isInAction,
      errorMsgAction,
    } = this.state;

    return (
      <Dialog
        open={showOrganizationDialog}
        onClose={() => this.onCloseDialog()}
        aria-labelledby="edit-dialog-title"
        aria-describedby="edit-dialog-description"
      >
        <DialogTitle>{organizationIndexEdit < 0 ? 'Add New' : 'Edit'} Organization</DialogTitle>
        <DialogContent className="w-96">
          {/* error notice */}
          {errorMsgAction ? (
            <Alert
              severity="error"
              className="mb-4"
              onClose={() => this.setState({ errorMsgAction: '' })}
            >
              {errorMsgAction}
            </Alert>
          ) : null}

          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Organization Name"
            label="Name"
            fullWidth
            value={organizationName}
            onChange={(e) => {
              this.setState({ organizationName: e.target.value });
            }}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => this.onSaveOrganization()} color="primary" disabled={isInAction}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderDeleteConfirmDialog(): ReactNode {
    return (
      <Dialog
        open={this.state.organizationIndexDelete >= 0}
        onClose={() => this.onCloseDialog()}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Are you sure you want to delete this organization?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The organization will be removed permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.onDeleteOrganization()} color="secondary">
            Delete
          </Button>
          <Button onClick={() => this.onCloseDialog()} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  async loadData() {
    this.props.setShowLoading(true);

    try {
      // get all industries
      const organizations = await ApiService.getOrganizations();
      this.setState({ organizations });
    } catch (e) {
      console.log(e);

      // show error
      if (e instanceof Error) this.setState({ errorMsg: e.message });
    }

    this.props.setShowLoading(false);
  }

  async onSaveOrganization() {
    const { organizations, organizationIndexEdit, organizationName } = this.state;

    this.setState({ isInAction: true });

    try {
      if (organizationIndexEdit >= 0) {
        const organization = organizations[organizationIndexEdit];
        organization.label = organizationName;

        await ApiService.updateOrganization(organizations[organizationIndexEdit]);
      } else {
        const organization = await ApiService.createOrganization(organizationName);
        organizations.push(organization);
      }

      // update list
      this.setState({
        organizations,
      });

      this.onCloseDialog();
    } catch (e) {
      console.log(e);

      // show error
      if (e instanceof Error) this.setState({ errorMsgAction: e.message });
    }

    this.setState({ isInAction: false });
  }

  async onDeleteOrganization() {
    const { organizations, organizationIndexDelete } = this.state;

    this.props.setShowLoading(true);

    try {
      await ApiService.deleteOrganization(organizations[organizationIndexDelete]);

      // remove from list
      organizations.splice(organizationIndexDelete, 1);
      this.setState({ organizations });
    } catch (e) {
      console.log(e);

      // show error
      if (e instanceof Error) this.setState({ errorMsg: e.message });
    }

    this.onCloseDialog();
    this.props.setShowLoading(false);
  }

  onCloseDialog() {
    this.setState({
      showOrganizationDialog: false,
      organizationIndexDelete: -1,
    });
  }
}

export interface Props extends IApplicationState, PropsFromDispatch, RouteComponentProps {}

interface PropsFromDispatch {
  setShowLoading: typeof setShowLoading;
}

const mapStateToProps = (state: IApplicationState) => state;
const mapDispatchToProps = {
  setShowLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);
