import { ISortOption } from '../components/SortMenu/SortMenu';

export class UiHelper {
  static instance: UiHelper;

  static getInstance(): UiHelper {
    if (!this.instance) {
      this.instance = new UiHelper();
    }

    return this.instance;
  }

  static sortOptionString(sort?: ISortOption): string {
    if (!sort) {
      return '';
    }

    return `${sort.asc ? '+' : '-'}${sort.by}`;
  }
}
