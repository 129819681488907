import { RouteComponentProps } from 'react-router-dom';
import React, { Component } from 'react';
import { Button, Divider } from '@material-ui/core';

export default class Footer extends Component<RouteComponentProps> {
  render(): React.ReactElement {
    return (
      <div className="flex py-4 justify-center">
        {/* actions */}
        <div className="flex">
          <Button className="outline-none normal-case text-gray-400" size="small">
            Terms of User
          </Button>
          <Divider orientation="vertical" className="mx-10" />
          <Button className="outline-none normal-case text-gray-400" size="small">
            Privacy Policy
          </Button>
          <Divider orientation="vertical" className="mx-10" />
          <Button className="outline-none normal-case text-gray-400" size="small">
            Report Abuse
          </Button>
        </div>
      </div>
    );
  }
}
