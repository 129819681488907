import { IUser, User } from './user';
import { ApiService } from '../services';
import _ from 'lodash';
import { IReport } from './report';

export interface IPost {
  _id: string;
  title: string;
  imgFileName: string;
  videoFileNames?: string[];
  tags: string[];
  industry: string | string[];
  downloadCount: number;
  header: string;
  footer: string;
  address?: string;
  attributionEnabled?: boolean;

  user: string | IUser;
  approvedAt?: Date;
  recommendedAt?: Date;
  createdAt: Date;

  reports: IReport[];
}

export class Post {
  static IMAGE_PATH = 'post-images';
  static VIDEO_PATH = 'post-videos';

  data: IPost;
  user?: User;

  constructor(post: IPost) {
    this.data = post;

    if (_.isObject(post.user)) {
      this.user = new User(post.user);
    }
  }

  imageUrl(): string {
    return `${ApiService.urlFileBase}/${Post.IMAGE_PATH}/${this.data.imgFileName}`;
  }

  videoUrls(): string[] {
    const videos = this.data.videoFileNames?.map(
      (vf) => `${ApiService.urlFileBase}/${Post.VIDEO_PATH}/${vf}`,
    );

    return videos ?? [];
  }
}
