import Axios from 'axios';
import store from '../redux/store/createStore';

const API = Axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/v1`,
});

API.interceptors.request.use(
  (request) => {
    const userReducer = store.getState().userReducer;

    // set token
    if (userReducer?.token) {
      request.headers.token = userReducer?.token;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default API;
