import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { IApplicationState } from '../redux/reducers';
import { connect } from 'react-redux';
import ROUTES from '../constants/routes';

class AuthRoute extends Component<Props> {
  render() {
    const Component = this.props.component;

    return (
      <Route
        render={(props) =>
          this.props.userReducer.user ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: props.location } }} />
          )
        }
      />
    );
  }
}

interface Props extends IApplicationState {
  component: React.ComponentType<any>;
}

const mapStateToProps = (state: IApplicationState) => state;

export default connect(mapStateToProps)(AuthRoute);
